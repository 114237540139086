import { BrowserRouter as Router ,Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import HomePage from "./pages/HomePage";
import GeneticScoresPage from "./pages/GeneticScoresPage"
import GenotypeListPage from "./pages/GenotypeListPage";
import GeneticStatsPage from "./pages/GeneticStatsPage";
import PhysicalStatsPage from "./pages/PhysicalStatsPage";
import GenePhysicalStatsPage from "./pages/GenePhysicalStatsPage";
import GenePhysicalStatsPage2 from "./pages/GenePhysicalStatsPage2";
import MessagePage from "./pages/Messages";
import Answers from "./pages/Answers";
import SendMessagePage from "./pages/SendMessagePage";
import GeneParamsDetailPage from "./pages/GeneParamsPage";
import PlayerListPage from "./pages/PlayerListPage";
import PlayerProfilePage from "./pages/PlayerProfilePage";
import EditPlayerInfoPage from "./pages/EditPlayerInfoPage";
import PlayerComparison from "./pages/PlayerComparison";
import NewPlayerPage from "./pages/NewPlayerPage";
import RegisterDataPage from "./pages/RegisterDataPage";
import PrivateRoute from "./components/PrivateRoute.jsx";

import { UserContext } from "./hooks/UserContext";
import useAuth from "./hooks/UseAuth";

function App() {
  const { user, setUser } = useAuth();

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/home"
            element={
              <PrivateRoute> 
              <HomePage />
              </PrivateRoute> 
            }
          />
          <Route
            path="/geneparametersdetail"
            element={
              <PrivateRoute>
              <GeneParamsDetailPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/register"
            element={
               
              <Register />
              
            }
          /> 
          <Route
            path="/geneticscores"
            element={
               <PrivateRoute> 
              <GeneticScoresPage />
              </PrivateRoute> 
            }
          />
          <Route
            path="/genotypelist"
            element={
               <PrivateRoute> 
              <GenotypeListPage />
              </PrivateRoute> 
            }
          />
          <Route
            path="/geneticstats"
            element={
              <PrivateRoute> 
              <GeneticStatsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/physicalstats"
            element={
              <PrivateRoute> */
              <PhysicalStatsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/genephysicalstats"
            element={
              <PrivateRoute>
              <GenePhysicalStatsPage />
              </PrivateRoute> 
            }
          />
          <Route
            path="/playerlist"
            element={
              <PrivateRoute> 
              <PlayerListPage />
              </PrivateRoute> 
            }
          />
          <Route
            path="/editplayer/:playerId"
            element={
              <PrivateRoute> 
              <EditPlayerInfoPage />
              </PrivateRoute> 
            }
          />
          <Route 
            path="/player/:playerId" 
            element={
              <PrivateRoute> 
              <PlayerProfilePage />
              </PrivateRoute>} />
          <Route
            path="/newplayer"
            element={
              <PrivateRoute> 
              <NewPlayerPage />
              </PrivateRoute> 
            }
          />
          <Route
            path="/registerdata"
            element={
              <PrivateRoute> 
              <RegisterDataPage />
              </PrivateRoute> 
            }
          />
          <Route
            path="/messages"
            element={
              <PrivateRoute> 
              <MessagePage />
              </PrivateRoute> 
            }
          />
          <Route
            path="/answers"
            element={
              <PrivateRoute>
              <Answers />
              </PrivateRoute> 
            }
          />
          <Route
            path="/sendmessages"
            element={
              <PrivateRoute> 
              <SendMessagePage />
              </PrivateRoute> 
            }
          />

          {/* <Route
            path="/genephysicalstats/leg_curl"
            element={
              <PrivateRoute>
                <AssociationLegCurl />
              </PrivateRoute>
            }
          />
          <Route
            path="/genephysicalstats/leg_ext"
            element={
              <PrivateRoute>
                <AssociationLegExt />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/genephysicalstats/benchpress"
            element={
              <PrivateRoute>
                <GenePhysicalStatsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/genephysicalstats/legcurl"
            element={
              <PrivateRoute>
                <GenePhysicalStatsPage2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/playercomparison"
            element={
              <PrivateRoute>
                <PlayerComparison />
              </PrivateRoute>
            }
          />

          {/* <Route
            path="/genephysicalstats/squat"
            element={
              <PrivateRoute>
                <AssociationSquat />
              </PrivateRoute>
            }
          />
          <Route
            path="/genephysicalstats/esneklik"
            element={
              <PrivateRoute>
                <AssociationEsneklik />
              </PrivateRoute>
            }
          />
          <Route
            path="/genephysicalstats/denge"
            element={
              <PrivateRoute>
                <AssociationDenge />
              </PrivateRoute>
            }
          /> */}
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;