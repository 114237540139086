import "./PlayerList.css";
import { DataGrid } from "@material-ui/data-grid";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { userRows } from "../../dummydata/dummyData";
/* import SummarizeIcon from "@mui/icons-material/Summarize"; */
import { Link } from "react-router-dom";
import { useState } from "react";
import React from "react";
import axios from "axios";
import random_avatar from "../.././assets/image/random_avatar.jpg";

export default function PlayerList() {
  const [data, setData] = useState(userRows);
  var datasize = Object.keys(data).length;
  //General Score Data
  const [new_player_data, setNewPlayerData] = useState(null);
  React.useEffect(() => {
    axios.get("/api/new_player_view").then((response) => {
      setNewPlayerData(response.data);
    });
  }, []);
  if (!new_player_data) return null;
  //

  const columns = [
    { field: "id", headerName: "Athlete No", width: 140 },
    {
      field: "ad_soyad",
      headerName: "Name",
      width: 170,

      renderCell: (params) => {
        return (
          <div className="playerListUser">
            <img className="playerListImg" src={random_avatar} alt="" />{" "}
            {/* have to come back for avatar */}
            {params.row.ad_soyad}
          </div>
        );
      },
      /*       renderCell: (ad_soyad) => {
        return (
          <div className="userListUser">
            <img className="userListImg" src={ad_soyad.row} alt="" />
            {ad_soyad.row.ad_soyad}
          </div>
        );
      }, */
    },
    { field: "dogum_tarihi", headerName: "Date of birth", width: 160 },
    { field: "pozisyon", headerName: "Position", width: 140 },
    { field: "durum", headerName: "Status", width: 120 },
    {
      field: "personalpage",
      headerName: "Personal Page",
      width: 170,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/player/" + params.row.id}>
              <ContactPageIcon className="playerListNavigateProfile" />
            </Link>
            {/*             <Link to={"/editplayer/" + params.row.id}>
              <button className="playerListEdit">DÜZENLE</button>
            </Link> */}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Edit Player",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {/*             <Link to={"/player/" + params.row.id}>
              <ContactPageIcon className="playerListNavigateProfile" />
            </Link> */}
            <Link to={"/editplayer/" + params.row.id}>
              <button className="playerListEdit">Edit</button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={new_player_data}
      disableSelectionOnClick
      columns={columns}
      pageSize={26}
      checkboxSelection
    />
  );
}
