import "./chart.css";
import React from "react";
import { useState } from "react";
import axios from "axios";
import {
  XAxis,
  Brush,
  Bar,
  BarChart,
  Label,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Chart({ title, dataKey, grid }) {
  //General Score Data
  const [GeneralScore, setGeneralScore] = useState(null);
  React.useEffect(() => {
    axios.get("/api/generalscore").then((response) => {
      setGeneralScore(response.data);
    });
  }, []);
  if (!GeneralScore) return null;

  //Label Names
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="tooltip">
          <p className="desc">{GeneralScore[label - 1]["ad_soyad"]}</p>
          <p className="label_power">{`Power Score : ${payload[0].value}`}</p>
          <p className="label_endurance">{`Endurance Score : ${payload[1].value}`}</p>
          <p className="label_motor">{`Motor Skill Score : ${payload[2].value}`}</p>
          <p className="label_oxygen">{`Oxygen Capacity : ${payload[3].value}`}</p>
          <p className="label_injury">{`Injury Risk : ${payload[4].value}`}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <div className="chart">
      <h3 className="chartTitle">{title}</h3>
      <ResponsiveContainer width="100%" aspect={4 / 1}>
        <BarChart data={GeneralScore}>
          <Legend
            verticalAlign="top"
            align="center"
            wrapperStyle={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
            style={{ fontSize: "1.5rem" }}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="id" height={50}>
            <Label
              value="Athlete ID"
              position="center"
              dy={10}
              style={{ fontSize: 20, fontWeight: 500 }}
            />
          </XAxis>
          <YAxis>
            <Label
              value="Parametric Scores"
              angle={-90}
              position="center"
              dx={-20}
              style={{ fontSize: 20, fontWeight: 500 }}
            />
          </YAxis>
          <Tooltip
            content={CustomTooltip}
            wrapperStyle={{
              backgroundColor: "white",
              borderStyle: "solid",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          />
          <Brush dataKey="id" height={20} stroke="#a4162b" />
          <Bar name="Power" dataKey="power" fill="#3256ed" />
          <Bar name="Endurance" dataKey="endurance" fill="#db4f9a" />
          <Bar name="Motor Skills" dataKey="motor" fill="#ffc440" />
          <Bar name="Oxygen Capacity" dataKey="oxygen" fill="#e43623" />
          <Bar name="Injury Risk" dataKey="injury" fill="#bb3ac2" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

