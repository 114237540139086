import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import Home from "./home/Home";
import "./HomePage.css"

const HomePage = () => {
    return (
            <div className="homewrapper">
                <Topbar />
                <div className="homecontainer">
                    <Sidebar />
                    <div className='homesubcontainer'>
                        <Home />
                    </div>
                </div>
            </div>
    )
}

export default HomePage
