import React from "react";
import axios from "axios";
import { useState } from "react";
import {
  Bar,
  BarChart,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
function BenchpressChart({ title }) {
  //data
  const [PhysicalScore, setPhysicalScore] = useState(null);
  React.useEffect(() => {
    axios.get("/api/demo_physical").then((response) => {
      setPhysicalScore(response.data);
    });
  }, []);
  //
  return (
    <div>
      <h3 className="chartTitle">{title}</h3>
      <BarChart width={800} height={250} data={PhysicalScore}>
        <CartesianGrid strokeDasharray="3 3" />
        <Label value="Athlete No" offset={-5} position="insideBottom" />
        <XAxis height={50} dataKey="sporcu">
          <Label
            value="Athlete No"
            position="center"
            dy={15}
            style={{ fontSize: 18, fontWeight: 500 }}
          />
        </XAxis>
        <YAxis>
          <Label
            value="KG"
            angle={-90}
            position="center"
            dx={-20}
            style={{ fontSize: 18, fontWeight: 500 }}
          />
        </YAxis>
        <Tooltip />
        <Legend verticalAlign="top" height={20} />
        <Bar
          name="Benchpress Maximum"
          dataKey="benchpres_max"
          fill="#0e4194"
          barSize={15}
        />
      </BarChart>
    </div>
  );
}

function SquatChart({ title }) {
  //data
  const [PhysicalScore, setPhysicalScore] = useState(null);
  React.useEffect(() => {
    axios.get("/api/demo_physical").then((response) => {
      setPhysicalScore(response.data);
    });
  }, []);
  //
  return (
    <div>
      <h3 className="chartTitle">{title}</h3>
      <BarChart width={800} height={250} data={PhysicalScore}>
        <CartesianGrid strokeDasharray="3 3" />
        <Label value="Athlete No" offset={-5} position="insideBottom" />
        <XAxis height={50} dataKey="sporcu">
          <Label
            value="Athlete No"
            position="center"
            dy={15}
            style={{ fontSize: 18, fontWeight: 500 }}
          />
        </XAxis>
        <YAxis
          label={{
            value: "KG",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip />
        <Legend verticalAlign="top" />
        <Bar
          name="Squat Maximum"
          barSize={15}
          dataKey="squat_max"
          fill="#0e4194"
        />
      </BarChart>
    </div>
  );
}
function DengeChart({ title }) {
  //data
  const [PhysicalScore, setPhysicalScore] = useState(null);
  React.useEffect(() => {
    axios.get("/api/demo_physical").then((response) => {
      setPhysicalScore(response.data);
    });
  }, []);
  //
  return (
    <div>
      <h3 className="chartTitle">{title}</h3>
      <BarChart width={800} height={250} data={PhysicalScore}>
        <CartesianGrid strokeDasharray="3 3" />
        <Label value="Athlete No" offset={-5} position="insideBottom" />
        <XAxis height={50} dataKey="sporcu">
          <Label
            value="Athlete No"
            position="center"
            dy={15}
            style={{ fontSize: 18, fontWeight: 500 }}
          />
        </XAxis>
        <YAxis
          label={{
            value: "Score",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip />
        <Legend verticalAlign="top" height={20} />
        <Bar
          name="Balance Right Leg"
          dataKey="denge_sag_ayak1"
          fill="#0e4194"
        />
        <Bar
          name="Balance Left Leg"
          dataKey="denge_sol_ayak1"
          fill="#00C49F"
        />
      </BarChart>
    </div>
  );
}
function LegcurlChart({ title }) {
  //data
  const [PhysicalScore, setPhysicalScore] = useState(null);
  React.useEffect(() => {
    axios.get("/api/demo_physical").then((response) => {
      setPhysicalScore(response.data);
    });
  }, []);
  //
  return (
    <div>
      <h3 className="chartTitle">{title}</h3>
      <BarChart width={800} height={250} data={PhysicalScore}>
        <CartesianGrid strokeDasharray="3 3" />
        <Label value="Athlete No" offset={-5} position="insideBottom" />
        <XAxis height={50} dataKey="sporcu">
          <Label
            value="Athlete No"
            position="center"
            dy={15}
            style={{ fontSize: 18, fontWeight: 500 }}
          />
        </XAxis>
        <YAxis
          label={{
            value: "KG",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip />
        <Legend verticalAlign="top" height={20} />
        <Bar
          name="Leg Curl Right Leg Maximum"
          dataKey="legcurl_sag_max"
          fill="#0e4194"
        />
        <Bar
          name="Leg Curl Left Leg Maximum"
          dataKey="legcurl_sol_max"
          fill="#00C49F"
        />
      </BarChart>
    </div>
  );
}

export { BenchpressChart, SquatChart, DengeChart, LegcurlChart };
