import React from 'react'
import Topbar from '.././components/topbar/Topbar';
import Sidebar from '.././components/sidebar/Sidebar';
import GenotypeList from './genotypeList/GenotypeList';
import './GenotypeListPage.css'

const GenotypeListPage = () => {
  return (
    <div className="genotypelistwrapper">
      <Topbar />
      <div className="genotypelistcontainer">
        <Sidebar />
        <div className="genotypelistsubcontainer">
          <GenotypeList />
        </div>
      </div>
    </div>
  );
}

export default GenotypeListPage
