import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import RegisterDataTable from '../components/registerdatatable/RegisterDataTable';
import './RegisterDataPage.css'

const RegisterDataPage = () => {
    return (
        <div className="registerdatawrapper">
            <Topbar />
            <div className="registerdatacontainer">
                <Sidebar />
                <div className='registerdatasubcontainer'>
                    <RegisterDataTable />
                </div>
            </div>
        </div>
    )
}

export default RegisterDataPage