import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import './Messages.css'
import { useState } from "react";
import axios from "axios";
const Messages = () => {
  //General Score Data
  const [new_player_data, setNewPlayerData] = useState(null);

  /*   var table = document.getElementById(new_player_data);
  var lastRow = table.rows[table.rows.length - 1]; */

  React.useEffect(() => {
    axios.get("/api/new_player_view_reverse").then((response) => {
      setNewPlayerData(response.data);
    });
  }, []);
  if (!new_player_data) return null;
  //
  return (
    <div className="messageswrapper">
      <Topbar />
      <div className="messagescontainer">
        <Sidebar />
        <div className="messagessubcontainer">
          <div className="message  message--error">
            <p>
              {/*               {messages_data[0]["first_message"]} */}

              <h4>
                {new_player_data[0]["ad_soyad"]}'s physical results did not
                registered.
              </h4>
              {/*                 Pastrami biltong sirloin alcatra ham hock ball tip short ribs
                tail chuck. Brisket turkey bacon ham porchetta ball tip.
                Andouille kielbasa pork loin turkey. */}
            </p>
          </div>

          <div className="message  message--warning">
            <p>
              <h4>
                {new_player_data[0]["ad_soyad"]}'s genetic results are pending.
              </h4>
              {/*               {messages_data[0]["second_message"]} */}
              {/*                 Pastrami fatback frankfurter ground round pork belly. Meatloaf
                landjaeger boudin pork strip steak. Bresaola tail capicola,
                salami landjaeger jerky pork loin tenderloin bacon filet mignon. */}
            </p>
          </div>

          <div className="message  message--success">
            <p>
              <h4>Player {new_player_data[0]["ad_soyad"]} has been added.</h4>
              {/*               {messages_data[0]["third_message"]} */}
              {/*                 Pig tri-tip ground round venison bresaola fatback prosciutto
                porchetta. Bresaola strip steak rump, turkey meatball pork pig
                t-bone porchetta cupim ham hock pancetta ham. */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages
