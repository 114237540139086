import React from "react";
import {
  CalendarToday,
  LocationSearching,
  PermIdentity,
  Publish,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import "./EditPlayerInfo.css";
import random_avatar from "../.././assets/image/random_avatar.jpg";
import Axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
export default function EditPlayerInfo() {
  const id = useParams();
  const [ad_soyad, setAd_soyad] = useState("");
  const [il, setIl] = useState("");
  const [ulke, setUlke] = useState("");
  const [pozisyon, setPozisyon] = useState("");
  const id_number = id["playerId"];

  //START New Player Table Data
  const [new_player_data, setNewPlayerData] = useState(null);
  React.useEffect(() => {
    Axios.get("/api/new_player_view").then((response) => {
      setNewPlayerData(response.data);
    });
  }, []);
  if (!new_player_data) return null;
  //END

  const deletePlayer = () => {
    Axios.post("/api/delete_player", {
      id_number: id_number,
    }).then((response) => {
      console.log(response);
    });
  };
  const updateName = () => {
    Axios.post("/api/update_name", {
      ad_soyad: ad_soyad,
      id_number: id_number,
    }).then((response) => {
      console.log(response);
    });
  };

  const updatePosition = () => {
    Axios.post("/api/update_position", {
      pozisyon: pozisyon,
      id_number: id_number,
    }).then((response) => {
      console.log(response);
    });
  };
  const updateCity = () => {
    Axios.post("/api/update_city", {
      il: il,
      id_number: id_number,
    }).then((response) => {
      console.log(response);
    });
  };
  const updateCountry = () => {
    Axios.post("/api/update_country", {
      ulke: ulke,
      id_number: id_number,
    }).then((response) => {
      console.log(response);
    });
  };
  function multiple_function_one_click() {
    updateName();
    updatePosition();
    updateCity();
    updateCountry();
  }
console.log(id_number[1]);
  return (
    <div className="player">
      <div className="playerTitleContainer">
        <h1 className="playerTitle">Edit Athlete Information</h1>
        <div className="playerDeleteContainer">
          <h3 className="platerDeleteTitle">Delete</h3>
          <Link to="/playerlist">
            <DeleteIcon 
              className="playerDeleteButton"
              onClick={() => deletePlayer()}
            />
          </Link>
        </div>
      </div>
      <div className="playerContainer">
        <div className="playerShow">
          <div className="playerShowTop">
            <img src={random_avatar} alt="" className="playerShowImg" />
            <div className="playerShowTopTitle">
              <span className="playerShowUsername">
                {new_player_data[id_number - 1]["ad_soyad"]}
              </span>
            </div>
          </div>

          <div className="playerShowBottom">
            <span className="playerShowTitle">Personal Information</span>
            <div className="playerShowInfo">
              <PermIdentity className="playerShowIcon" />
              <span className="playerShowInfoTitle">
                {new_player_data[id_number - 1]["pozisyon"]}
              </span>
            </div>
            <div className="playerShowInfo">
              <CalendarToday className="playerShowIcon" />
              <span className="playerShowInfoTitle">
                {new_player_data[id_number - 1]["dogum_tarihi"]}
              </span>
            </div>
            <div className="playerShowInfo">
              <LocationSearching className="playerShowIcon" />
              <span className="playerShowInfoTitle">
                {new_player_data[id_number - 1]["il"]}
              </span>
            </div>
            <div className="playerShowInfo">
              <LocationSearching className="playerShowIcon" />
              <span className="playerShowInfoTitle">
                {new_player_data[id_number - 1]["ulke"]}
              </span>
            </div>
          </div>
        </div>
        <div className="playerUpdate">
          <span className="playerUpdateTitle">Edit</span>
          <form className="playerUpdateForm">
            <div className="playerUpdateLeft">
              <div className="playerUpdateItem">
                <label>Name</label>
                <input
                  type="text"
                  placeholder={
                    "Current:" + new_player_data[id_number - 1]["ad_soyad"]
                  }
                  className="playerUpdateInput"
                  onChange={(event) => {
                    setAd_soyad(event.target.value);
                  }}
                />
                              <button
                className="playerUpdateButton"
                onClick={updateName}
              >
                Update Name
              </button>
              </div>
              <div className="playerUpdateItem">
                <label>Position</label>
                <input
                  type="text"
                  placeholder={
                    "Current:" +
                    new_player_data[id_number - 1]["pozisyon"]
                  }
                  className="playerUpdateInput"
                  onChange={(event) => {
                    setPozisyon(event.target.value);
                  }}
                />
                              <button
                className="playerUpdateButton"
                onClick={updatePosition}
              >
                Update Position
              </button>
              </div>
              <div className="playerUpdateItem">
                <label>City</label>
                <input
                  type="text"
                  placeholder={
                    "Current:" + new_player_data[id_number - 1]["il"]
                  }
                  className="playerUpdateInput"
                  onChange={(event) => {
                    setIl(event.target.value);
                  }}
                />
                              <button
                className="playerUpdateButton"
                onClick={updateCity}
              >
                Update City
              </button>
              </div>
              <div className="playerUpdateItem">
                <label>Country</label>
                <input
                  type="text"
                  placeholder={
                    "Current:" + new_player_data[id_number - 1]["ulke"]
                  }
                  className="playerUpdateInput"
                  onChange={(event) => {
                    setUlke(event.target.value);
                  }}
                />
                              <button
                className="playerUpdateButton"
                onClick={updateCountry}
              >
                Update Country
              </button>
              </div>
            </div>
            <div className="playerUpdateRight">
{/*               <div className="playerUpdateUpload">
                <img className="playerUpdateImg" src={random_avatar} alt="" />
                <label htmlFor="file">
                  <Publish className="playerUpdateIcon" />
                </label>
                <input type="file" id="file" style={{ display: "none" }} />
              </div>
              <button
                className="playerUpdateButton"
                onClick={multiple_function_one_click}
              >
                Update
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
