import "./GenotypeList.css";
import { DataGrid } from "@material-ui/data-grid";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { genotypeRows } from "../../dummydata/dummyData";
 import { Link } from "react-router-dom";
 import { useState } from "react";
 import axios from "axios";
 export default function GenotypeList() {
   const [data, setData] = useState(genotypeRows);

   /*   const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  }; */

   ////PDF START
   const getPDF = async (id) => {
     await axios
       .get(`/api/get/${id}`, {
         responseType: "blob",
       })
       .then((res) => {
         if (res.data) {
           const file = new Blob([res.data], { type: "application/pdf" });
           const fileURL = URL.createObjectURL(file);
           window.open(fileURL);
         }
       });
   };
   ////PDF END

   const columns = [
     {
       field: "username",
       headerName: "Name-Surname",
       width: 170,
       renderCell: (params) => {
         return (
           <div className="genotypeListUser">
             <img className="genotypeListImg" src={params.row.avatar} alt="" />
             {params.row.username}
           </div>
         );
       },
     },
     { field: "actn3", headerName: "ACTN3", width: 120 },
     { field: "ppara", headerName: "PPARA", width: 120 },
     { field: "adrb2", headerName: "ADRB2", width: 120 },
     { field: "ppargc1a", headerName: "PPARGC1A", width: 150 },
     { field: "nos3", headerName: "NOS3", width: 120 },
     { field: "hif1a", headerName: "HIF1A", width: 120 },
     { field: "col1a1", headerName: "COL1A1", width: 150 },
     { field: "col5a1", headerName: "COL5A1", width: 150 },
     { field: "mct1", headerName: "MCT1", width: 120 },
     { field: "il6", headerName: "IL6", width: 120 },
     { field: "bdnf", headerName: "BDNF", width: 120 },
     { field: "comt", headerName: "COMT", width: 120 },
     {
       field: "report",
       headerName: "Report",
       width: 120,
       renderCell: (params) => {
         return (
           <>
             {/* <Link >
              <button className="userListEdit">PROFİL</button> 
            </Link> */}
             <SummarizeIcon
               className="genotypeListNavigateReport"
               onClick={() => getPDF(params.row.id)}
             />
             {/* <DeleteIcon
              className="scoreListDelete"
              onClick={() => handleDelete(params.row.id)}
            /> */}
           </>
         );
       },
     },
     {
      field: "action",
      headerName: "Personal Page",
      width: 170,
      renderCell: (params) => {
        return (
          <>
            {/* <Link >
             <button className="userListEdit">PROFİL</button> 
           </Link> */}
            <Link to={"/player/" + params.row.id}>
              <ContactPageIcon className="genotypeListNavigateProfile" />
            </Link>
            {/* <DeleteIcon
             className="scoreListDelete"
             onClick={() => handleDelete(params.row.id)}
           /> */}
          </>
        );
      },
    },
   ];

   return (
     <DataGrid
       rows={data}
       disableSelectionOnClick
       columns={columns}
       pageSize={10}
       checkboxSelection
     />
   );
 }
