import React from 'react'
import "./GenePhysicalStatsPage.css";
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
/* import ScoreCard from "../components/scorecard/ScoreCard"; */
import AssociationBenchpress from "./association/AssociationBenchpress.js";
import AssociationNavBar from "./association/AssociationNavBar.js";

const GenePhysicalPage = () => {
  return (
    <div className="genephysicalwrapper">
      <Topbar />
      <div className="genephysicalcontainer">
        <Sidebar />
        <div className="genephysicalsubcontainer">
          <AssociationNavBar />
          <AssociationBenchpress />
        </div>
      </div>
    </div>
  );
};

export default GenePhysicalPage
