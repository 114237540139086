import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import GeneticStats from "./geneticStats/GeneticStats"
import "./GeneticStatsPage.css"

const GeneticStatsPage = () => {
  return (
    <div className="geneticstatswrapper">
      <Topbar />
      <div className="geneticstatscontainer">
        <Sidebar />
        <div className='geneticstatssubcontainer'>
          <GeneticStats />
        </div>
      </div>
    </div>
  )
}

export default GeneticStatsPage
