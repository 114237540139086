import Chart from "../../components/chart/Chart";
import ScoreCard from "../../components/scorecard/ScoreCard";
import "./Home.css";
/* import { userData } from "../../dummydata/dummyData"; */
import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";


export default function Home() {
  
  return (
    <div className="home">
      <ScoreCard />
      <Chart
        data="GeneralScore"
        title="Bar Chart of Parametric Genetic Scores"
      />
      {/* <div className="homeWidgets">
        <WidgetSm />
        <WidgetLg />
      </div> */}
    </div>
  );
}
