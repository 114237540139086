import React from "react";
import { Nav, Bars, NavMenu, NavBtnLink } from "./NavbarElements";
const AssociationNavBar = () => {
  return (
    <div>
      <Nav>
        <Bars />
        <NavMenu>
          <NavBtnLink to="/genephysicalstats/benchpress">Bench Press</NavBtnLink>
          <NavBtnLink to="/genephysicalstats/legcurl">Leg Curl</NavBtnLink>
          <NavBtnLink>Leg Extension</NavBtnLink>
          <NavBtnLink>Squat</NavBtnLink>
          <NavBtnLink>Flexibility</NavBtnLink>
          <NavBtnLink>Balance</NavBtnLink>
        </NavMenu>
      </Nav>
    </div>
  );
};

export default AssociationNavBar;
