import React from 'react'
import {
  BenchpressChart,
  LegcurlChart,
  SquatChart,
  DengeChart,
} from "../../components/chart/PhysicalCharts";
/* import ScoreCard from "../../components/scorecard/ScoreCard"; */
/* import { userData } from "../../dummydata/dummyData"; */
/* import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg"; */
import "./PhysicalStats.css";

const PhysicalStats = () => {
  return (
    <div className="physicalstatswrapper">
      <div className="physicalstatssubcontainer">
        <div className="physicalstatsgrid-item">
          <BenchpressChart title="Team Performance of Benchpress Exercise" />
        </div>
      </div>

      <div className="physicalstatssubcontainer">
        <div className="physicalstatsgrid-item">
          <SquatChart title="Team Performance of Squat Exercise" />
        </div>
      </div>

      <div className="physicalstatssubcontainer">
        <div className="physicalstatsgrid-item">
          <DengeChart title="Team Performance of Balance Exercise" />
        </div>
      </div>

      <div className="physicalstatssubcontainer">
        <div className="physicalstatsgrid-item">
          <LegcurlChart title="Team Performance of LegCurl Exercise" />
        </div>
      </div>
    </div>
  );
};

export default PhysicalStats
