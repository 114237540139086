import React from 'react'
import ParametricCharts from "../../components/chart/ParametricCharts";
import ScoreCard from "../../components/scorecard/ScoreCard";
/* import { userData } from "../../dummydata/dummyData"; */
/* import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg"; */
import "./GeneticStats.css";

const GeneticStats = () => {
  return (
    <div className="geneticstatswrapper">
      <ScoreCard></ScoreCard>
      <div className="geneticstatssubcontainer">
        <div className="geneticstatsgrid-item">
          <ParametricCharts
            title="Power Score Team Results "
            parameter_dataName="Power"
            parameter_dataKey="power"
            team_parameter="fake_team_power"
            EUR_parameter="power_EUR"
          />
        </div>
      </div>

      <div className="geneticstatssubcontainer">
        <div className="geneticstatsgrid-item">
          <ParametricCharts
            title="Endurance Score Team Results"
            parameter_dataName="Endurance"
            parameter_dataKey="endurance"
            team_parameter="fake_team_endurance"
            EUR_parameter="endurance_EUR"
          />
        </div>
      </div>

      <div className="geneticstatssubcontainer">
        <div className="geneticstatsgrid-item">
          <ParametricCharts
            title="Motor Skills Team Results"
            parameter_dataName="Motor Skills"
            parameter_dataKey="motor"
            team_parameter="fake_team_motor"
            EUR_parameter="motor_EUR"
          />
        </div>
      </div>

      <div className="geneticstatssubcontainer">
        <div className="geneticstatsgrid-item">
          <ParametricCharts
            title="Oxygen Capacity Team Results"
            parameter_dataName="Oxygen Capacity"
            parameter_dataKey="oxygen"
            team_parameter="fake_team_oxygen"
            EUR_parameter="oxygen_EUR"
          />
        </div>
      </div>

      <div className="geneticstatssubcontainer">
        <div className="geneticstatsgrid-item">
          <ParametricCharts
            title="Injury Risk Team Results"
            parameter_dataName="Injury Risk"
            parameter_dataKey="injury"
            team_parameter="fake_team_injury"
            EUR_parameter="injury_EUR"
          />
        </div>
      </div>
    </div>
  );
};

export default GeneticStats
