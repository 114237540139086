import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./Login.css";
import logo from "../assets/logo/UniqgeneLogo.png"
import { UserContext } from "../hooks/UserContext";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const { setUser } = React.useContext(UserContext);

  const Auth = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/login", {
          email: email,
          password: password,
        })
        .then((res) => {
          if (res.data.accessToken) {
            setUser(res.data.name);

            console.log(res.data.name);
          }
        });
        
      navigate("/home");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  return (
    <div className="LoginContainer" id="container">
      <div className="form-container sign-in-container">
        <form onSubmit={Auth} action="#">
          <h2>
            UAE National Cricket Team Performance Dashboard
            <br></br>Trainer Log In
          </h2>
          <p>{msg}</p>
          <input
            type="email"
            placeholder="Enter your e-mail address."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Enter your password."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <a href="#">Forgot your password?</a> */}
          <button disabled={!validateForm()}>Login</button>
        </form>
      </div>
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-panel overlay-right">
            <img src={logo} alt="UniqgeneLogo" className="uniqgenelogo" />
          </div>
        </div>
      </div>
    </div>
  );

}