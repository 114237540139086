import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import "./Messages.css";
import { useState } from "react";
import axios from "axios";
const Messages = () => {
  //General Score Data
  const [new_player_data, setNewPlayerData] = useState(null);
  const [contact_form_messages, setContact] = useState(null);
  React.useEffect(() => {
    axios.get("/api/new_player_view").then((response) => {
      setNewPlayerData(response.data);
    });
    axios.get("/api/contact_form").then((response) => {
      setContact(response.data);
    });
  }, []);
  if (!new_player_data) return null;
  if (!contact_form_messages) return null;
  //
  return (
    <div className="messageswrapper">
      <Topbar />
      <div className="messagescontainer">
        <Sidebar />
        <div className="messagessubcontainer">
          {/*           <div className="message  message--error">
            <p>
              <h4>Your pending message: {contact_form_messages[0]["mesaj"]}</h4>
            </p>
          </div> */}

          <div className="message  message--warning">
            <p>
              <h4>
                Your pending message:
                <p>{contact_form_messages[0]["mesaj"]}</p>
              </h4>
            </p>
          </div>

          <div className="message  message--success">
            <p>
              <h4>
                Your message with the subject "
                {contact_form_messages[0]["konu"]}" has been delivered.
              </h4>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
