import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import PlayerList from './playerList/PlayerList.js';
import './PlayerListPage.css'

const PlayerListPage = () => {
    return (
        <div className="playerlistpagewrapper">
            <Topbar />
            <div className="playerlistpagecontainer">
                <Sidebar />
                <div className='playerlistpagesubcontainer'>
                    <PlayerList />
                </div>
            </div>
        </div>
    )
}

export default PlayerListPage