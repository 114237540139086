import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import PlayerProfile from './playerProfile/PlayerProfile';
import './PlayerProfilePage.css'

const PlayerProfilePage = () => {
    return (
        <div className="playerprofilepagewrapper">
            <Topbar />
            <div className="playerprofilepagecontainer">
                <Sidebar />
                <div className='playerprofilepagesubcontainer'>
                    <PlayerProfile />
                </div>
            </div>
        </div>
    )
}

export default PlayerProfilePage