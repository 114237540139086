import "./sidebar.css";
import {
  Timeline,
  TrendingUp,
  PermIdentity,
  Storefront,
  ChatBubbleOutline,
} from "@material-ui/icons";
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from "react-router-dom";
import React from "react";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import ScorePng from '../../assets/icon/score.png';
import DNAPng from '../../assets/icon/dna.png';
import ExercisePng from '../../assets/icon/exercise.png';
import DNAExercisePng from '../../assets/icon/dna-physical.png';
import DNAStatsPng from '../../assets/icon/dna-stats.png';
import HowToRegIcon from "@mui/icons-material/HowToReg";

export default function Sidebar() {
  const [isActive, setIsActive] = React.useState(false);

  const activeTab = () => {
    // toggle
    setIsActive(!isActive);

    // or set to true
    //setIsActive(true);
  };

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
{/*           <div className="sidebar-dropdown">
            <select id="" className="sidebar-dropbtn">
              <option value="selectteam">SELECT TEAM</option>
              <option value="alfateam">A Team</option>
              <option value="under16/17">U16/17</option>
              <option value="under13/14">U13/14</option>
            </select>
          </div> */}

          <Link to="/home" className="link">
            <li
              className={isActive ? "sidebarListItem" : "sidebarListItemActive"}
              onClick={activeTab}
            >
              <HomeIcon className="sidebarIcon" />
              Home
            </li>
          </Link>

          <Link to="/geneparametersdetail" className="link">
            <li className="sidebarListItem">
              <InfoIcon className="sidebarIcon" />
              Details of Genetic Parameters
            </li>
          </Link>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Team Statistics</h3>
          <ul className="sidebarList">
            <Link to="/geneticscores" className="link">
              <li className="sidebarListItem active">
                <img src={ScorePng} className="sidebarIcon" />
                Genetic Scores
              </li>
            </Link>

            <Link to="/geneticstats" className="link">
              <li className="sidebarListItem">
                <img src={DNAStatsPng} className="sidebarIcon" />
                Genetic Statistics
              </li>
            </Link>
{/*             <Link  to="/genotypelist" className="link">
              <li className="sidebarListItemPassive">
                <img src={DNAPng} className="sidebarIcon" />
                Genotype Results ( inactive )
              </li>
            </Link> */}

            <Link /* to="/physicalstats" */ className="link">
              <li className="sidebarListItemPassive">
                <img src={ExercisePng} className="sidebarIcon" />
                Physical Statistics ( inactive )
              </li>
            </Link>

            <Link /* to="/genephysicalstats"  */ className="link">
              <li className="sidebarListItemPassive">
                <img src={DNAExercisePng} className="sidebarIcon" />
                Genetics - Physical Relations (inactive)
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle"> Athlete Statistics</h3>
          <ul className="sidebarList">
            <Link to="/playerlist" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Player List
              </li>
            </Link>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Interactive</h3>

          <ul className="sidebarList">
{/*             <Link  to="/newplayer"  className="link">
              <li className="sidebarListItem active">
                <HowToRegIcon className="sidebarIcon" />
                Add New Player ( inactive )
              </li>
            </Link> */}
                <Link to="/playercomparison" className="link">
              <li className="sidebarListItemPassive">
                <StackedLineChartIcon className="sidebarIcon" />
                Player Comparison
              </li>
            </Link>

              <li className="sidebarListItemPassive">
                <SaveIcon className="sidebarIcon" />
                Physical Data Register ( inactive )
              </li>

            <li className="sidebarListItemPassive">
              <PivotTableChartIcon className="sidebarIcon" />
              Create Your Table ( inactive )
            </li>


            {/*             <Link to="/newplayer" className="link">
              <li className="sidebarListItem active">
                <PermIdentity className="sidebarIcon" />
                Add New Player
              </li>
            </Link> */}
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Notifications</h3>
          <ul className="sidebarList">
            <Link to="/messages" className="link">
              <li className="sidebarListItem">
                <ChatBubbleOutline className="sidebarIcon" />
                Messages
              </li>
            </Link>

            <Link to="/sendmessages" className="link">
              <li className="sidebarListItem">
                <ForwardToInboxIcon className="sidebarIcon" />
                Contact Form
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}
