import React from "react";
import { useState } from "react";
import axios from "axios";
import {
  Line,
  Bar,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
} from "recharts";
export default function ParametricCharts({
  title,
  parameter_dataKey,
  parameter_dataName,
  team_parameter,
  EUR_parameter,
}) {
  //General Score Data
  const [GeneralScore, setGeneralScore] = useState(null);
  React.useEffect(() => {
    axios.get("/api/generalscore").then((response) => {
      setGeneralScore(response.data);
    });
  }, []);
  if (!GeneralScore) return null;
  //Label Names
  const CustomTooltip_Parametric = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="tooltip">
          <p className="desc">
            {GeneralScore[label - 1]["ad_soyad"]} Score : {payload[0].value}
          </p>
          {/* <p className="label">{`Skor : ${payload[0].value}`}</p> */}
          <p className="label_EU">{`European Avg. : ${payload[1].value}`}</p>
          <p className="label_Team">{`Team Avg. : ${payload[2].value}`}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <div>
      <h3 className="chartTitle">{title}</h3>
      <ComposedChart width={700} height={300} data={GeneralScore}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis height={50} dataKey="id">
          <Label
            value="Athlete No"
            position="center"
            dy={15}
            style={{ fontSize: 18, fontWeight: 500 }}
          />
        </XAxis>
        <YAxis>
          <Label
            value={parameter_dataName}
            angle={-90}
            position="center"
            dx={-20}
            style={{ fontSize: 18, fontWeight: 500 }}
          />
        </YAxis>
        <Tooltip
          cursor={false}
          content={CustomTooltip_Parametric}
          wrapperStyle={{
            backgroundColor: "white",
            borderStyle: "solid",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        />
        <Legend
          verticalAlign="top"
          align="center"
          wrapperStyle={{
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        />
        <Bar
          name={parameter_dataName}
          dataKey={parameter_dataKey}
          fill="#a4162b"
          barSize={15}
        />

        <Line
          name="European Avg."
          type="monotone"
          dataKey={EUR_parameter}
          dot={false}
          stroke="black"
          strokeWidth={2}
        />
        <Line
          name="Team Avg."
          type="monotone"
          dataKey={team_parameter}
          dot={false}
          stroke="#f2c971"
          strokeWidth={2}
        />
      </ComposedChart>
    </div>
  );
}