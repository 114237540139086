import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import "./PlayerComparison.css";
import { useState } from "react";
import axios from "axios";
import RadarPlot from "../components/chart/RadarPlot";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
function PlayerComparison() {
  //General Score Data, Physical Data
  const [GeneralScore, setGeneralScore] = useState(null);
  const [PhysicalScore, setPhysicalScore] = useState(null);
  //For button and inputs
  const [athlete1, setAthlete1] = useState(1);
  const [athlete2, setAthlete2] = useState(1);
  const [updated1, setUpdated1] = useState(athlete1);
  const [updated2, setUpdated2] = useState(athlete2);

  const handleChange1 = (event) => {
    setAthlete1(event.target.value);
  };
  const handleChange2 = (event) => {
    setAthlete2(event.target.value);
  };
  const handleClick1 = () => {
    // 👇 "message" stores input field value
    setUpdated1(athlete1);
  };
  const handleClick2 = () => {
    // 👇 "message" stores input field value
    setUpdated2(athlete2);
  };
  React.useEffect(() => {
    axios.get("/api/generalscore").then((response) => {
      setGeneralScore(response.data);
    });
  }, []);
  if (!GeneralScore) return null;
  //
  /*   console.log(PhysicalScore); */

  const comparison_data_genetics = [
    {
      name: "Power",
      first_athlete: GeneralScore[updated1 - 1]["power"],
      second_athlete: GeneralScore[updated2 - 1]["power"],
      amt: 2400,
    },
    {
      name: "Endurance",
      first_athlete: GeneralScore[updated1 - 1]["endurance"],
      second_athlete: GeneralScore[updated2 - 1]["endurance"],
      amt: 2210,
    },
    {
      name: "Injury Risk",
      first_athlete: GeneralScore[updated1 - 1]["injury"],
      second_athlete: GeneralScore[updated2 - 1]["injury"],
      amt: 2290,
    },
    {
      name: "Oxygen Capacity",
      first_athlete: GeneralScore[updated1 - 1]["oxygen"],
      second_athlete: GeneralScore[updated2 - 1]["oxygen"],
      amt: 2000,
    },
    {
      name: "Motor Skill",
      first_athlete: GeneralScore[updated1 - 1]["motor"],
      second_athlete: GeneralScore[updated2 - 1]["motor"],
      amt: 2181,
    },
  ];

/*   const comparison_data_physical = [
    {
      name: "Balance Right",
      first_athlete: PhysicalScore[updated1 - 1]["denge_sag_ayak1"],
      second_athlete: PhysicalScore[updated2 - 1]["denge_sag_ayak1"],
      amt: 2400,
    },
    {
      name: "Balance Left",
      first_athlete: PhysicalScore[updated1 - 1]["denge_sol_ayak1"],
      second_athlete: PhysicalScore[updated2 - 1]["denge_sol_ayak1"],
      amt: 2210,
    },
    {
      name: "Benchpress Max",
      first_athlete: PhysicalScore[updated1 - 1]["benchpres_max"],
      second_athlete: PhysicalScore[updated2 - 1]["benchpres_max"],
      amt: 2290,
    },
    {
      name: "Squat Max",
      first_athlete: PhysicalScore[updated1 - 1]["squat_max"],
      second_athlete: PhysicalScore[updated2 - 1]["squat_max"],
      amt: 2000,
    },
    {
      name: "Legcurl Right Max",
      first_athlete: PhysicalScore[updated1 - 1]["legcurl_sag_max"],
      second_athlete: PhysicalScore[updated2 - 1]["legcurl_sag_max"],
      amt: 2181,
    },
    {
      name: "Legcurl Left Max:",
      first_athlete: PhysicalScore[updated1 - 1]["legcurl_sol_max"],
      second_athlete: PhysicalScore[updated2 - 1]["legcurl_sol_max"],
      amt: 2181,
    },
  ]; */
  return (
    <div className="comparisonwrapper">
      <Topbar />
      <div className="comparisoncontainer">
        <Sidebar />
        <div className="comparisonsubcontainer">
          <div className="comparisonsubcontainer-column">
            <div className="column">
              <div>
                <span>First player:</span>
              </div>
              <div>
                <input
                  type="number"
                  placeholder={"Player ID"}
                  className="playerUpdateInput"
                  value={athlete1}
                  onChange={handleChange1}
                />
              </div>
              <div>
                <span>{GeneralScore[athlete1 - 1]["ad_soyad"]}</span>
              </div>
              <div>
                <button onClick={handleClick1} className="select-button">
                  Select
                </button>
              </div>
              <div>
                
                <div className="comparisonsubsubcontainer">
                  <h2> {GeneralScore[updated1 - 1]["ad_soyad"]}</h2>
                  <RadarPlot id_number={updated1}></RadarPlot>
                </div>
                <div className="comparisonsubsubcontainer">
                  <h2>Comparison of genetic statistics</h2>

                  <BarChart
                    width={600}
                    height={300}
                    data={comparison_data_genetics}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar name={GeneralScore[athlete1 - 1]["ad_soyad"]} dataKey="first_athlete" fill="#8884d8" />
                    <Bar name={GeneralScore[athlete2 - 1]["ad_soyad"]}dataKey="second_athlete" fill="#82ca9d" />
                  </BarChart>
                </div>

                {/*                 <h2>Genetic Scores</h2>
                <p>Power: {GeneralScore[updated1 - 1]["power"]}</p>
                <p>Endurance:{GeneralScore[updated1 - 1]["endurance"]}</p>
                <p>Oxygen:{GeneralScore[updated1 - 1]["oxygen"]}</p>
                <p>Injury:{GeneralScore[updated1 - 1]["injury"]}</p>
                <p>Motor:{GeneralScore[updated1 - 1]["motor"]}</p>
                <h2>Physical</h2>
                <p>
                  Balance Right:{PhysicalScore[updated1 - 1]["denge_sag_ayak1"]}
                </p>
                <p>
                  Balance Left:{PhysicalScore[updated1 - 1]["denge_sol_ayak1"]}
                </p>
                <p>
                  Benchpress Max:{PhysicalScore[updated1 - 1]["benchpres_max"]}
                </p>
                <p>Squat Max:{PhysicalScore[updated1 - 1]["squat_max"]}</p>
                <p>
                  Legcurl Right Max:
                  {PhysicalScore[updated1 - 1]["legcurl_sag_max"]}
                </p>
                <p>
                  Legcurl Left Max:
                  {GeneralScore[updated1 - 1]["legcurl_sol_max"]}
                </p> */}
              </div>
            </div>

            <div className="column">
              <div>
                <span>Second player:</span>
              </div>

              <div>
                <input
                  type="number"
                  placeholder={"Player ID"}
                  className="playerUpdateInput"
                  value={athlete2}
                  onChange={handleChange2}
                />
              </div>

              <div>
                <span>{GeneralScore[athlete2 - 1]["ad_soyad"]}</span>
              </div>

              <div>
                <button onClick={handleClick2} className="select-button">
                  Select
                </button>
              </div>

              <div>
                <div className="comparisonsubsubcontainer">
                  <h2>{GeneralScore[updated2 - 1]["ad_soyad"]}</h2>
                  <RadarPlot id_number={updated2}></RadarPlot>
                </div>
{/*                 <div className="comparisonsubsubcontainer">
                  <h2>Comparison of physical statistics</h2>


                  <BarChart
                    width={500}
                    height={300}
                    data={comparison_data_physical}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="first_athlete"
                      name={GeneralScore[updated1 - 1]["ad_soyad"]}
                      fill="#8884d8"
                    />
                    <Bar
                      dataKey="second_athlete"
                      name={GeneralScore[updated2 - 1]["ad_soyad"]}
                      fill="#82ca9d"
                    />
                  </BarChart>
                </div> */}
                {/* <h2>Genetic Scores</h2>
                <p>Power: {GeneralScore[updated2 - 1]["power"]}</p>
                <p>Endurance:{GeneralScore[updated2 - 1]["endurance"]}</p>
                <p>Oxygen:{GeneralScore[updated2 - 1]["oxygen"]}</p>
                <p>Injury:{GeneralScore[updated2 - 1]["injury"]}</p>
                <p>Motor:{GeneralScore[updated2 - 1]["motor"]}</p>
                <h2>Physical</h2>
                <p>
                  Balance Right:{PhysicalScore[updated2 - 1]["denge_sag_ayak1"]}
                </p>
                <p>
                  Balance Left:{PhysicalScore[updated2 - 1]["denge_sol_ayak1"]}
                </p>
                <p>
                  Benchpress Max:{PhysicalScore[updated2 - 1]["benchpres_max"]}
                </p>
                <p>Squat Max:{PhysicalScore[updated2 - 1]["squat_max"]}</p>
                <p>
                  Legcurl Right Max:
                  {PhysicalScore[updated2 - 1]["legcurl_sag_max"]}
                </p>
                <p>
                  Legcurl Left Max:
                  {GeneralScore[updated2 - 1]["legcurl_sol_max"]}
                </p> */}
              </div>
            </div>
          </div>
          {/*
           */}
        </div>
      </div>
    </div>
  );
}

export default PlayerComparison;
