import { useContext } from "react";

import { UserContext } from "../hooks/UserContext.js";

/* import {  useNavigate} from "react-router-dom"; */

const  PrivateRoute = ({ children }) => {
  const { user } = useContext(UserContext);

  /*     const navigate = useNavigate();  */

  if (user === null) {
    window.location.href = "/";
  } else {
    return children;
  }
}

export default PrivateRoute;