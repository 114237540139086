import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import PhysicalStats from "./physicalStats/PhysicalStats"
import "./PhysicalStatsPage.css"

const PhysicalStatsPage = () => {
  return (
    <div className="physicalstatswrapper">
      <Topbar />
      <div className="physicalstatscontainer">
        <Sidebar />
        <div className='physicalstatssubcontainer'>
          <PhysicalStats />
        </div>
      </div>
    </div>
  )
}

export default PhysicalStatsPage