import React from 'react'
import "./GenePhysicalStatsPage2.css";
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
/* import ScoreCard from "../components/scorecard/ScoreCard"; */
import AssociationLegCurl from "./association/AssociationLegCurl.js";
import AssociationNavBar from "./association/AssociationNavBar.js";

const GenePhysicalPage2 = () => {
  return (
    <div className="genephysicalwrapper2">
      <Topbar />
      <div className="genephysicalcontainer2">
        <Sidebar />
        <div className="genephysicalsubcontainer2">
          <AssociationNavBar />
          <AssociationLegCurl />
        </div>
      </div>
    </div>
  );
};

export default GenePhysicalPage2
