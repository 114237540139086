import "./NewPlayer.css";
import { useState } from "react";
import Axios from "axios";
import Button from "@mui/material/Button";

export default function NewPlayer() {
  const [id, setSporcuNo] = useState("");
  const [ad_soyad, setAd_soyad] = useState("");
  const [il, setIl] = useState("");
  const [ulke, setUlke] = useState("");
  const [dogum_tarihi, setDogumTarihi] = useState("");
  const [cinsiyet, setCinsiyet] = useState("");
  const [durum, setDurum] = useState("");
  const [pozisyon, setPozisyon] = useState("");

  const [userList, setUserList] = useState([]);

  const addNewPlayer = () => {
    Axios.post("/api/new_player", {
      id: id,
      ad_soyad: ad_soyad,
      dogum_tarihi: dogum_tarihi,
      il: il,
      ulke: ulke,
      cinsiyet: cinsiyet,
      durum: durum,
      pozisyon: pozisyon,
    }).then((response) => {
      console.log(response);
    });
  };

  const getNewPlayer = () => {
    Axios.get("/api/new_player_view").then((response) => {
      setUserList(response.data);
    });
  };
  return (
    <div className="newPlayer">
      <h1 className="newPlayerTitle">Add New Player</h1>
      <form className="newPlayerForm">
        <div className="newPlayerItem">
          <label>Name Surname</label>
          <input
            type="text"
            placeholder="Player's Name and Surname..."
            onChange={(event) => {
              setAd_soyad(event.target.value);
            }}
          />
        </div>
        <div className="newPlayerItem">
          <label>Position</label>
          <input
            type="text"
            placeholder="Player's Position..."
            onChange={(event) => {
              setPozisyon(event.target.value);
            }}
          />
        </div>
        <div className="newPlayerItem">
          <label>Date of birth</label>
          <input
            type="date"
            placeholder="Oyuncunun Doğum Tarihini Giriniz..."
            onChange={(event) => {
              setDogumTarihi(event.target.value);
            }}
          />
        </div>
        <div className="newPlayerItem">
          <label>City</label>
          <input
            type="text"
            placeholder="İstanbul"
            onChange={(event) => {
              setIl(event.target.value);
            }}
          />
        </div>
        <div className="newPlayerItem">
          <label>Country</label>
          <input
            type="text"
            placeholder="Turkiye"
            onChange={(event) => {
              setUlke(event.target.value);
            }}
          />
        </div>
        <div className="newPlayerItem">
          <label>Gender</label>
          <select
            className="newPlayerSelect"
            name="cinsiyet"
            id="cinsiyet"
            onChange={(event) => {
              var e = document.getElementById("cinsiyet").value;
              setCinsiyet(e);
            }}
          >
            <option value="erkek">Male</option>
            <option value="kadin">Female</option>
            <option value="diger">Other</option>
          </select>
        </div>
        {/* <label>Cinsiyet</label>
          <div className="newPlayerGender">
            <input type="radio" name="gender" id="male" value="male" />
            <label for="male">Erkek</label>
            <input type="radio" name="gender" id="female" value="female" />
            <label for="female">Kadın</label>
            <input type="radio" name="gender" id="other" value="other" />
            <label for="other">Diğer</label>
          </div> */}

        <div className="newPlayerItem">
          <label>Status</label>
          <select
            className="newPlayerSelect"
            name="activepassive"
            id="activepassive"
            onChange={(event) => {
              var e = document.getElementById("activepassive").value;
              setDurum(e);
            }}
          >
            <option value="aktif">Active</option>
            <option value="pasif">Passive</option>
          </select>
        </div>
        <div>
          <Button className="newPlayerButton" onClick={addNewPlayer}>
            Add Player
          </Button>
        </div>
        {/*         <h2>
          Verinin geldiğini görmek için sonradan kaldırılacak(biraz geç geliyor)
        </h2>
        {getNewPlayer()}
        {userList.map((val, key) => {
          return (
            <table>
              <tbody>
                <tr>
                  <td>İsim: {val.ad_soyad}</td>
                  <td>dogum_tarihi: {val.dogum_tarihi}</td>
                  <td>İl: {val.il}</td>
                  <td>ulke: {val.ulke}</td>
                  <td>durum : {val.durum}</td>
                  <td>pozisyon: {val.pozisyon}</td>
                </tr>
              </tbody>
            </table>
          );
        })} */}
      </form>
    </div>
  );
}
