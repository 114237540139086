import React from "react";
import "./AssociationLegCurl.css";
import Topbar from "../.././components/topbar/Topbar";
import Sidebar from "../.././components/sidebar/Sidebar";
import {
  Actn3_freq_pie,
  Adrb2_freq_pie,
  Col5a1_freq_pie,
  Hif1a_freq_pie,
  Nos3_freq_pie,
} from "./TeamAlleleFreq.js";
import AssociationNavBar from "./AssociationNavBar.js";
import {
  Tooltip,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
} from "recharts";

//Leg Curl Data
const leg_curl_actn3 = [
  { name: "CC", sag_value: 44.5 },
  { name: "CT", sag_value: 36.0 },
  { name: "TT", sag_value: 36.0 },
  { name: "CC", sol_value: 44.0 },
  { name: "CT", sol_value: 35.85 },
  { name: "TT", sol_value: 35.0 },
];
const leg_curl_adrb2 = [
  { name: "AA", sag_value: 32.5 },
  { name: "AG", sag_value: 23.33 },
  { name: "GG", sag_value: 36.25 },
  { name: "AA", sol_value: 32.5 },
  { name: "AG", sol_value: 23.33 },
  { name: "GG", sol_value: 28.75 },
];
const leg_curl_hif1a = [
  { name: "CC", sag_value: 31.66 },
  { name: "CT", sag_value: 30.0 },
  { name: "TT", sag_value: 35.0 },
  { name: "CC", sol_value: 28.33 },
  { name: "CT", sol_value: 30 },
  { name: "TT", sol_value: 35.0 },
];
const leg_curl_nos3 = [
  { name: "CC", sag_value: 25.0 },
  { name: "CT", sag_value: 28.33 },
  { name: "TT", sag_value: 35.83 },
  { name: "CC", sol_value: 25 },
  { name: "CT", sol_value: 28.33 },
  { name: "TT", sol_value: 35.83 },
];

const AssociationLegCurl = () => {
  return (
        <div>
          <h1 fontFamily="Poppins">Leg Curl</h1>

          <div className="grid-container">
            <div><Actn3_freq_pie className="grid-item1"></Actn3_freq_pie></div>
            <div><Hif1a_freq_pie className="grid-item2"></Hif1a_freq_pie></div>
            {/* <div><Adrb2_freq_pie className="grid-item3"></Adrb2_freq_pie></div>
            <div><Nos3_freq_pie className="grid-item4"></Nos3_freq_pie></div> */}
          
            <div className="grid-item3">
              ACTN3 Related with Leg Curl
              <BarChart
                width={500}
                height={300}
                data={leg_curl_actn3}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{
                    value: "Kg",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar name="Right Leg" dataKey="sag_value" fill="#0e4194" />
                <Bar name="Left Leg" dataKey="sol_value" fill="#82ca9d" />
              </BarChart>
            </div>
            <div className="grid-item4">
              HIF1A Related with Leg Curl
              <BarChart
                width={500}
                height={300}
                data={leg_curl_hif1a}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{
                    value: "Kg",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar name="Right Leg" dataKey="sag_value" fill="#0e4194" />
                <Bar name="Left Leg" dataKey="sol_value" fill="#82ca9d" />
              </BarChart>
            </div>
            {/* <div className="GFgrid-item8">
              ADRB2 Related with Leg Curl
              <BarChart
                width={500}
                height={300}
                data={leg_curl_adrb2}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{
                    value: "KG",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar name="Sağ Ayak" dataKey="sag_value" fill="#0e4194" />
                <Bar name="Sol Ayak" dataKey="sol_value" fill="#82ca9d" />
              </BarChart>
            </div> */}
            {/* <div className="GFgrid-item9">
              NOS3 Related with Leg Curl
              <BarChart
                width={500}
                height={300}
                data={leg_curl_nos3}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{
                    value: "KG",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar name="Sağ Ayak" dataKey="sag_value" fill="#0e4194" />
                <Bar name="Sol Ayak" dataKey="sol_value" fill="#82ca9d" />
              </BarChart>
            </div> */}
          </div>
        </div>
  );
};

export default AssociationLegCurl;
