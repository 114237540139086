import "./ScoreCard.css";
import React from "react";
import { useState } from "react";
import axios from "axios";

const team_average_URL = "/api/team_averages"; //data URL
export default function ScoreCard() {
  //Data
  const [Team_Average_Scores, setTeamAverage] = useState(null);
  React.useEffect(() => {
    axios.get(team_average_URL).then((response) => {
      setTeamAverage(response.data);
    });
  }, []);
  if (!Team_Average_Scores) return null;
  //
  return (
    <div className="featured">
      <div className="featuredItem" color="#a4162b">
        <span className="featuredTitle">
          Power <br></br> Score
        </span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">
            {Team_Average_Scores[0]["team_power"]}
          </span>
          {/* <span className="featuredMoneyRate">
            -11.4 <ArrowDownward  className="featuredIcon negative"/>
          </span> */}
        </div>
        <span className="featuredSub">Team Average</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">
          Endurance <br></br> Score
        </span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">
            {Team_Average_Scores[0]["team_endurance"]}
          </span>
          {/* <span className="featuredMoneyRate">
            -1.4 <ArrowDownward className="featuredIcon negative"/>
          </span> */}
        </div>
        <span className="featuredSub">Team Average</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">
          Motor <br></br> Skills
        </span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">
            {Team_Average_Scores[0]["team_motor"]}
          </span>
          {/* <span className="featuredMoneyRate">
            +2.4 <ArrowUpward className="featuredIcon"/>
          </span> */}
        </div>
        <span className="featuredSub">Team Average</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">
          Oxygen <br></br> Capacity
        </span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">
            {Team_Average_Scores[0]["team_oxygen"]}
          </span>
          {/* <span className="featuredMoneyRate">
            +2.4 <ArrowUpward className="featuredIcon"/>
          </span> */}
        </div>
        <span className="featuredSub">Team Average</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">
          Injury <br></br> Risk
        </span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">
            {Team_Average_Scores[0]["team_injury"]}
          </span>
          {/* <span className="featuredMoneyRate">
            +2.4 <ArrowUpward className="featuredIcon"/>
          </span> */}
        </div>
        <span className="featuredSub">Team Average</span>
      </div>
    </div>
  );
}
