import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarRadiusAxis,
  PolarAngleAxis,
  Tooltip,
  Legend,
} from "recharts";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

export default function RadarPlot({ id_number }) {
  /*   const id = useParams();
  const id_number = id["playerId"]; */
  //General Score Data
  const [GeneralScore, setGeneralScore] = useState(null);
  React.useEffect(() => {
    axios.get("/api/generalscore").then((response) => {
      setGeneralScore(response.data);
    });
  }, []);
  if (!GeneralScore) return null;
  let radar_data = [
    {
      subject: "Power",
      person: GeneralScore[id_number - 1]["power"],
      team: GeneralScore[id_number - 1]["fake_team_power"],
    },
    {
      subject: "Endurance",
      person: GeneralScore[id_number - 1]["endurance"],
      team: GeneralScore[id_number - 1]["fake_team_endurance"],
    },
    {
      subject: "Motor Skills",
      person: GeneralScore[id_number - 1]["motor"],
      team: GeneralScore[id_number - 1]["fake_team_motor"],
    },
    {
      subject: "Injury Risk",
      person: GeneralScore[id_number - 1]["injury"],
      team: GeneralScore[id_number - 1]["fake_team_injury"],
    },
    {
      subject: "Oxygen Capacity",
      person: GeneralScore[id_number - 1]["oxygen"],
      team: GeneralScore[id_number - 1]["fake_team_oxygen"],
    },
  ];
  return (
    <div>
      <RadarChart
        outerRadius={100}
        width={500}
        height={300}
        data={radar_data}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="subject" />
        <PolarRadiusAxis
          angle={90}
          domain={[0, 100]}
          tickCount={6}
          style={{ fontSize: 20 }}
        />
        <Tooltip />
        <Radar
          name={GeneralScore[id_number - 1]["ad_soyad"]}
          dataKey="person"
          stroke="#a4162b"
          fill="#a4162b"
          fillOpacity={0.6}
        />
        <Radar
          name={"Team Average"}
          dataKey="team"
          stroke="#0e4194"
          fill="#0e4194"
          fillOpacity={0.6}
        />
        <Legend layout="horizontal" verticalAlign="bottom" align="center" />
      </RadarChart>
    </div>
  );
}
