import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import EditPlayerInfo from './editPlayerInfo/EditPlayerInfo.js';
import "./EditPlayerInfoPage.css"

const EditPlayerInfoPage = () => {
  return (
    <div className="playerinfopagewrapper">
      <Topbar />
      <div className="playerinfopagecontainer">
        <Sidebar />
        <div className="playerinfopagesubcontainer">
          <EditPlayerInfo />
        </div>
      </div>
    </div>
  );
}

export default EditPlayerInfoPage