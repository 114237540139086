import React from "react";
import "./RegisterDataTable.css";
import { useState } from "react";
import axios from "axios";

export default function RegisterDataTable() {
  const [id, setSporcuNo] = useState("");
  const [ad_soyad, setAd_soyad] = useState("");
  const [pozisyon, setPozisyon] = useState("");
  const [agirlik, setAgirlik] = useState("");
  const [tekrar, setTekrar] = useState("");
  const [set, setSet] = useState("");
  const [tarih, setTarih] = useState("01.01.2022");
  const [egzersiz, setEgzersiz] = useState("");
  const [userList, setUserList] = useState([]);
  const [updatedDate, setUpdated] = useState(tarih);
  //Post func. for data registering.
  const addPhysicalData = () => {
    axios
      .post("/api/create", {
        id: id,
        ad_soyad: ad_soyad,
        pozisyon: pozisyon,
        agirlik: agirlik,
        tekrar: tekrar,
        set: set,
        tarih: tarih,
        egzersiz: egzersiz,
      })
      .then((response) => {
        console.log(response);
      });
  };
  const getSelectedDate = () => {
    axios
      .post("/api/selected_date_data", {
        updatedDate: updatedDate,
      })
      .then((response) => {
        console.log(response);
        setUserList(response.data);
      });
  };

  function select_and_get() {
    setUpdated(tarih);
    getSelectedDate();
  }
  return (
    <div>
      <div>
        <label for="exercise">Physical Exercise</label>
        <select
          id="physicaldatatype"
          name="exercise"
          onChange={(event) => {
            var e = document.getElementById("physicaldatatype").value;
            setEgzersiz(e);
          }}
        >
          <option value="squat">Squat</option>
          <option value="benchpress">BenchPress</option>
          <option value="legcurl">Leg Curl</option>
        </select>
        <label>Enter the date the exercise was performed:</label>
        <input
          type="date"
          id="dateofcalendar"
          value={tarih}
          onChange={(event) => {
            setTarih(event.target.value);
          }}
        ></input>

        <button onClick={select_and_get}>View Selected Date</button>
      </div>

      <div>
        <table className="table">
          <thead>
            <tr>
              <th className="header-label">Athlete No</th>
              <th className="header-label">Name Surname</th>
              <th className="header-label">Position</th>
              <th className="header-label">Kg</th>
              <th className="header-label">Repetition</th>
              <th className="header-label">Set</th>
              <th className="header-label">Action</th>
            </tr>
            <tr className="tr-inputs">
              <th>
                <div>
                  <input
                    type="number"
                    placeholder="Enter ID"
                    onChange={(event) => {
                      setSporcuNo(event.target.value);
                    }}
                  />
                </div>
              </th>
              <th>
                <div>
                  <input
                    type="string"
                    placeholder="Enter Name-Surname"
                    onChange={(event) => {
                      setAd_soyad(event.target.value);
                    }}
                  />
                </div>
              </th>
              <th>
                <div>
                  <input
                    type="string"
                    placeholder="Enter Position"
                    onChange={(event) => {
                      setPozisyon(event.target.value);
                    }}
                  />
                </div>
              </th>
              <th>
                <div>
                  <input
                    type="string"
                    placeholder="Enter Weight"
                    onChange={(event) => {
                      setAgirlik(event.target.value);
                    }}
                  />
                </div>
              </th>
              <th>
                <div>
                  <input
                    type="string"
                    placeholder="Enter Number of Repetitions"
                    onChange={(event) => {
                      setTekrar(event.target.value);
                    }}
                  />
                </div>
              </th>
              <th>
                <div>
                  <input
                    type="string"
                    placeholder="Enter Number of Sets"
                    onChange={(event) => {
                      setSet(event.target.value);
                    }}
                  />
                </div>
              </th>
              <th>
                <button
                  onClick={addPhysicalData}
                  style={{ marginTop: "10px", cursor: "pointer" }}
                >
                  Save
                </button>
              </th>
            </tr>
          </thead>

          <tbody>
            {/*             {getSelectedDate()} */}

            {userList.map((val, key) => {
              return (
                <tr>
                  <td>{val.id}</td>
                  <td>{val.ad_soyad}</td>
                  <td>{val.pozisyon}</td>
                  <td>{val.agirlik}</td>
                  <td>{val.set}</td>
                  <td>{val.tekrar}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
