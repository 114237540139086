import "./ScoreList.css";
import { DataGrid } from "@material-ui/data-grid";
/* import DeleteIcon from "@mui/icons-material/Delete"; */
import ContactPageIcon from "@mui/icons-material/ContactPage";
/* import { userRows } from "../../dummydata/dummyData"; */
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Link } from "react-router-dom";
import { useState } from "react";
import React from "react";
import axios from "axios";
import random_avatar from "../.././assets/image/random_avatar.jpg";
export default function ScoreList() {

  //General Score Data
  const [GeneralScore, setGeneralScore] = useState(null);
  React.useEffect(() => {
    axios.get("/api/generalscore").then((response) => {
      setGeneralScore(response.data);
    });
  }, []);
  if (!GeneralScore) return null;
  //
  ////PDF START
  const getPDF = async (id) => {
    await axios
      .get(`/api/get/${GeneralScore[id - 1]["swapcode"]}`, {
        responseType: "blob",
      })
      .then((res) => {
        if (res.data) {
          const file = new Blob([res.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      });
  };
  ////PDF END
  const columns = [
    {
      field: "ad_soyad",
      headerName: "Name",
      width: 170,

      renderCell: (params) => {
        return (
          <div className="scoreListUser">
            <img className="scoreListImg" src={random_avatar} alt="" />{" "}
            {/* have to come back for avatar */}
            {params.row.ad_soyad}
          </div>
        );
      },
      /*       renderCell: (ad_soyad) => {
        return (
          <div className="scoreListUser">
            <img className="scoreListImg" src={ad_soyad.row} alt="" />
            {ad_soyad.row.ad_soyad}
          </div>
        );
      }, */
    },
    { field: "power", headerName: "Power", width: 120 },
    { field: "endurance", headerName: "Endurance", width: 150 },
    { field: "injury", headerName: "Injury", width: 120 },
    { field: "oxygen", headerName: "Oxygen", width: 140 },
    { field: "motor", headerName: "Motor", width: 120 },
    {
      field: "report",
      headerName: "Report",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            {/* <Link >
              <button className="userListEdit">PROFİL</button> 
            </Link> */}
            <SummarizeIcon
              className="scoreListNavigateReport"
              onClick={() => getPDF(params.row.id)}
            />
            {/* <DeleteIcon
              className="scoreListDelete"
              onClick={() => handleDelete(params.row.id)}
            /> */}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Personal Page",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            {/* <Link >
              <button className="userListEdit">PROFİL</button> 
            </Link> */}
            <Link to={"/player/" + params.row.id}>
              <ContactPageIcon className="scoreListNavigateProfile" />
            </Link>
            {/* <DeleteIcon
              className="scoreListDelete"
              onClick={() => handleDelete(params.row.id)}
            /> */}
          </>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={GeneralScore}
      disableSelectionOnClick
      columns={columns}
      pageSize={26}
      checkboxSelection
    />
  );
}
