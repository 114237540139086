import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import './GeneParamsPage.css'
import $ from "jquery";
const GeneParamsDetailPage = () => {
  $(document).ready(function () {
    $("#myselection").on("change", function () {
      var value = $(this).val();
      $("div.myDiv").hide();
      $("#show" + value).show();
    });
  });
  return (
    <div className="geneparamsdetailwrapper">
      <Topbar />
      <div className="geneparamsdetailcontainer">
        <Sidebar />
        <div className="geneparamsdetailsubcontainer">

          <div className="geneparamsdetailwidgetLg">
            <select id="myselection">
              <option>Select the Parameter You Want to Get Information</option>
              <option value="PowerParametric">
                <strong> Muscle Fiber Type</strong>
              </option>
              <option value="InjuryParametric">
                <strong> Injury Risk and Recovery</strong>
              </option>
              <option value="OxygenParametric">
                <strong>Oxygen Capacity</strong>
              </option>
              <option value="CirculationParametric">
                <strong> Adaptation To Circulatory system </strong>
              </option>
              <option value="LactateParametric">
                <strong>Lactate Accumulation</strong>
              </option>
              <option value="MetabolismParametric">
                <strong> Energy Metabolism</strong>
              </option>
              <option value="MotorParametric">
                <strong> Motor Learning Skills</strong>
              </option>
            </select>

            <div id="showPowerParametric" className="myDiv">
              <h3 className="geneparamsdetailwidgetLgTitle">
                Muscle Fiber Type
              </h3>
              <p>
                The genetics test to choose training type is important in
                determining whether the athlete's muscle type has explosive
                power or the endurance characteristic that responds better to
                prolonged intense training. ACTN3 gene which is the most studied
                region in explaining the effect of genetic traits on sports
                performance in many populations genetically determines the
                production of Type I or Type II muscle types in the body. PPARa
                encodes proteins that affect the oxidation of fatty acids and
                affect the energy use and endurance factor during training. This
                parameter plays an important role in choosing the type of
                training in terms of maintaining the muscle structure and energy
                of the individual.
              </p>
            </div>


            <div id="showInjuryParametric" className="myDiv">
              <h3 className="geneparamsdetailwidgetLgTitle">
                Injury Risk and Recovery
              </h3>
              <p>
                Inflammation after intense training is a defense method used by
                the body to improve the immune system depending on genetic
                factors to protect the body against environmental factors and to
                repair damaged tissue that may occur in the body after training.
                IL-6 gene variants are involved in controlling inflammation
                after training. COL5A1 is one of the genes involved in collagen
                production; It is also effective in determining the mobility of
                muscles and tendons. Type-1 collagen which is encoded by Col1A1
                gene is found in tendon and connective tissues to provide
                stiffness and strength. It is known that these 2 genes belonging
                to the collagen-encoding gene family are related to soft tissue
                injuries and recovery times.
              </p>
            </div>


            <div id="showOxygenParametric" className="myDiv">
              <h3 className="geneparamsdetailwidgetLgTitle">Oxygen Capacity</h3>
              <p>
                When it comes to athletic performance, along with our physical
                characteristics, metabolism, vascular structure and oxygen level
                in the blood, and the ability of the vessels to carry oxygen to
                the tissues and transmit oxygen to the tissues are also
                important, because the molecules necessary for the muscle to
                work come from the organs to the relevant tissues thanks to the
                blood and vascular structure. The ability of the muscles to use
                oxygen is also related to the molecules in the tissue and
                involved in signal transmission. While the nitric oxide produced
                by the NOS3 gene determines the oxygen utilization capacity of
                the muscle tissue, HIF1-a is found in the oxidative muscle
                fibers. Various variants in both genes affect the level of
                adaptation to aerobic and anaerobic training.
              </p>
            </div>


            <div id="showMetabolismParametric" className="myDiv">
              <h3 className="geneparamsdetailwidgetLgTitle">
                Energy Metabolism
              </h3>
              <p>
                Conservation of energy during and after training is highly
                related to the absorption and metabolism of macronutrients,
                vitamins and minerals taken into the body. As with other
                parameters, metabolism also differs from individual to
                individual, and it affects each individual differently during
                training. Conversion of glucose into energy, conversion of fat
                molecules into energy units with oxygen are metabolic events
                that are effective in preserving the energy of the athlete
                throughout the training or match. PPARA and PPARGC1A, which are
                also effective in determining muscle types and control energy
                metabolism with fat burning, are the gene regions tested in this
                parameter.
              </p>
            </div>


            <div id="showCirculationParametric" className="myDiv">
              <h3 className="geneparamsdetailwidgetLgTitle">
                Adaptation To Circulatory system
              </h3>
              <p>
                Adaptation of the circulatory system to training; Ensuring
                intra-body balance in individuals who do sports for a long time
                is very important for the cardiovascular health of the athlete
                and also for a healthy sports life. Adaptation to training and
                features such as blood pressure, vascular structure and
                formation of new vessels constitute important factors for the
                biological development of the athlete. BDKRB2 and ADRB2 contain
                important variants that affect vascularity and heart function.
              </p>
            </div>


            <div id="showLactateParametric" className="myDiv">
              <h3 className="geneparamsdetailwidgetLgTitle">
                Lactate Accumulation
              </h3>
              <p>
                The breakdown of glucose in muscle tissues can occur with or
                without oxygen. The lactate molecule that emerges during the
                breakdown passes from the muscle tissues to the blood together
                with the ions. In cases where the proteins that provide the
                passage of lactate are less in the cell membrane, lactate
                molecules and ions accumulate in the muscle tissues. Inside the
                cell, the pH shifts towards acid and training-related factors
                such as fatigue or muscle injuries can be seen. Intracellular
                and extracellular lactate uptake and excretion is provided by
                proteins called MCTs. The variant in the gene region encoding
                this protein causes the MCT protein to be produced at different
                rates in tissues. This difference may also affect the rate of
                lactate uptake and removal from cells.
              </p>
            </div>


            <div id="showMotorParametric" className="myDiv">
              <h3 className="geneparamsdetailwidgetLgTitle">
                Motor Learning Skills
              </h3>
              <p>
                In sports, complex biological activities come together. Quick
                decision-making skills, learning, action and risk taking,
                competitive behavior are among these biological factors.
                Although these factors are thought to be related to behavior,
                there are very important brain activities behind them. The COMT
                gene is a factor that regulates chemicals in the brain that
                provide dopamine and similar communication between neurons. The
                enzyme produced by the COMT gene, decision making; It is stated
                that it causes differences in many subjects such as novelty
                seeking, harm avoidance, motivation. The BDNF gene, which
                provides the production of another factor, is involved in
                learning skills and establishing new neural connections. BDNF is
                an important factor in establishing new neural connections.
              </p>
            </div>
            <div />
          </div>
        </div>
      </div>
      </div>
      );
};

      export default GeneParamsDetailPage