import UserImage from ".././assets/image/user.png"

export const userData = [
    {
      name: "Jan",
      "Active User": 4000,
    },
    {
      name: "Feb",
      "Active User": 3000,
    },
    {
      name: "Mar",
      "Active User": 5000,
    },
    {
      name: "Apr",
      "Active User": 4000,
    },
    {
      name: "May",
      "Active User": 3000,
    },
    {
      name: "Jun",
      "Active User": 2000,
    },
    {
      name: "Jul",
      "Active User": 4000,
    },
    {
      name: "Agu",
      "Active User": 3000,
    },
    {
      name: "Sep",
      "Active User": 4000,
    },
    {
      name: "Oct",
      "Active User": 1000,
    },
    {
      name: "Nov",
      "Active User": 4000,
    },
    {
      name: "Dec",
      "Active User": 3000,
    },
  ];

  export const productData = [
    {
      name: "Jan",
      "Sales": 4000,
    },
    {
      name: "Feb",
      "Sales": 3000,
    },
    {
      name: "Mar",
      "Sales": 5000,
    },
  ];

  export const genotypeRows = [
    {
      id: 1,
      username: "Lebron James",
      avatar: UserImage,
      actn3: "XX",
      ppara: "GG",
      adrb2: "AA",
      ppargc1a: "GG",
      nos3: "CC",
      hif1a: "CC",
      col1a1: "GG",
      col5a1: "GG",
      mct1: "AA",
      il6: "CC",
      bdnf: "GG",
      comt: "AA",
    },
    {
      id: 2,
      username: "Kobe Bryant",
      avatar: UserImage,
      actn3: "XX",
      ppara: "GG",
      adrb2: "AA",
      ppargc1a: "GG",
      nos3: "CC",
      hif1a: "CC",
      col1a1: "GG",
      col5a1: "GG",
      mct1: "AA",
      il6: "CC",
      bdnf: "GG",
      comt: "AA",
    },
    {
      id: 3,
      username: "Cristiano Ronaldo",
      avatar: UserImage,
      actn3: "XX",
      ppara: "GG",
      adrb2: "AA",
      ppargc1a: "GA",
      nos3: "CT",
      hif1a: "CT",
      col1a1: "GA",
      col5a1: "GG",
      mct1: "AA",
      il6: "CC",
      bdnf: "GG",
      comt: "AA",
    },
    {
      id: 4,
      username: "Lionel Messi",
      avatar: UserImage,
      actn3: "RX",
      ppara: "GC",
      adrb2: "AG",
      ppargc1a: "GA",
      nos3: "CT",
      hif1a: "CT",
      col1a1: "GA",
      col5a1: "GC",
      mct1: "AT",
      il6: "CG",
      bdnf: "GA",
      comt: "AA",
    },
    {
      id: 5,
      username: "Maria Sharapova",
      avatar: UserImage,
      actn3: "XX",
      ppara: "GG",
      adrb2: "AA",
      ppargc1a: "GG",
      nos3: "CC",
      hif1a: "CC",
      col1a1: "GG",
      col5a1: "GG",
      mct1: "AA",
      il6: "CG",
      bdnf: "GA",
      comt: "AA",
    },
    {
      id: 6,
      username: "Michael Jordan",
      avatar: UserImage,
      actn3: "XX",
      ppara: "GG",
      adrb2: "AA",
      ppargc1a: "GG",
      nos3: "CC",
      hif1a: "CC",
      col1a1: "GG",
      col5a1: "GG",
      mct1: "AA",
      il6: "CC",
      bdnf: "GG",
      comt: "AA",
    },
    {
      id: 7,
      username: "Serena Williams",
      avatar: UserImage,
      actn3: "XX",
      ppara: "GG",
      adrb2: "AA",
      ppargc1a: "GG",
      nos3: "CC",
      hif1a: "CC",
      col1a1: "GG",
      col5a1: "GG",
      mct1: "AA",
      il6: "CC",
      bdnf: "GG",
      comt: "AA",
    },
    {
      id: 8,
      username: "Rafael Nadal",
      avatar: UserImage,
      actn3: "RX",
      ppara: "GC",
      adrb2: "AG",
      ppargc1a: "GG",
      nos3: "CC",
      hif1a: "CC",
      col1a1: "GG",
      col5a1: "GG",
      mct1: "AA",
      il6: "CC",
      bdnf: "GG",
      comt: "AG",
    },
    {
      id: 9,
      username: "Lewis Hamilton",
      avatar: UserImage,
      actn3: "RX",
      ppara: "GC",
      adrb2: "AG",
      ppargc1a: "GA",
      nos3: "CT",
      hif1a: "CT",
      col1a1: "GA",
      col5a1: "GC",
      mct1: "AT",
      il6: "CG",
      bdnf: "GA",
      comt: "GG",
    },
    {
      id: 10,
      username: "Tayfun Çomut",
      avatar: UserImage,
      actn3: "XX",
      ppara: "GG",
      adrb2: "AA",
      ppargc1a: "GG",
      nos3: "CC",
      hif1a: "CC",
      col1a1: "GG",
      col5a1: "GG",
      mct1: "AA",
      il6: "CC",
      bdnf: "GG",
      comt: "AG",
    },
    {
      id: 11,
      username: "Miray Çantay",
      avatar: UserImage,
      actn3: "RR",
      ppara: "GG",
      adrb2: "AA",
      ppargc1a: "GG",
      nos3: "CC",
      hif1a: "TT",
      col1a1: "GG",
      col5a1: "GG",
      mct1: "AA",
      il6: "CC",
      bdnf: "GG",
      comt: "AA",
    },
  ];
  

  export const userRows = [
    {
      id: 1,
      username: "Irmak Ege",
      avatar: UserImage,
      dateofbirth: "12/02/1994",
      position: "Kaleci",
      power: "50",
      endurance: "50",
      injury: "50",
      oxygen: "50",
      motor: "50",
      status: "aktif",
    },
    {
      id: 2,
      username: "Irmak Ege",
      avatar: UserImage,
      dateofbirth: "12/02/1994",
      position: "Kaleci",
      power: "50",
      endurance: "50",
      injury: "50",
      oxygen: "50",
      motor: "50",
      status: "aktif",
    },
    {
      id: 3,
      username: "Irmak Ege",
      avatar: UserImage,
      dateofbirth: "12/02/1994",
      position: "Kaleci",
      power: "50",
      endurance: "50",
      injury: "50",
      oxygen: "50",
      motor: "50",
      status: "aktif",
    },
    {
      id: 4,
      username: "Irmak Ege",
      avatar: UserImage,
      dateofbirth: "12/02/1994",
      position: "Kaleci",
      power: "50",
      endurance: "50",
      injury: "50",
      oxygen: "50",
      motor: "50",
      status: "aktif",
    },
    {
      id: 5,
      username: "Irmak Ege",
      avatar: UserImage,
      dateofbirth: "12/02/1994",
      position: "Kaleci",
      power: "50",
      endurance: "50",
      injury: "50",
      oxygen: "50",
      motor: "50",
      status: "aktif",
    },
    {
      id: 6,
      username: "Irmak Ege",
      avatar: UserImage,
      dateofbirth: "12/02/1994",
      position: "Kaleci",
      power: "50",
      endurance: "50",
      injury: "50",
      oxygen: "50",
      motor: "50",
      status: "aktif",
    },
    {
      id: 7,
      username: "Irmak Ege",
      avatar: UserImage,
      dateofbirth: "12/02/1994",
      position: "Kaleci",
      power: "50",
      endurance: "50",
      injury: "50",
      oxygen: "50",
      motor: "50",
      status: "aktif",
    },
    {
      id: 8,
      username: "Irmak Ege",
      avatar: UserImage,
      dateofbirth: "12/02/1994",
      position: "Kaleci",
      power: "50",
      endurance: "50",
      injury: "50",
      oxygen: "50",
      motor: "50",
      status: "aktif",
    },
    {
      id: 9,
      username: "Irmak Ege",
      avatar: UserImage,
      dateofbirth: "12/02/1994",
      position: "Kaleci",
      power: "50",
      endurance: "50",
      injury: "50",
      oxygen: "50",
      motor: "50",
      status: "aktif",

    },
    {
      id: 10,
      username: "Irmak Ege",
      avatar: UserImage,
      dateofbirth: "12/02/1994",
      position: "Kaleci",
      power: "50",
      endurance: "50",
      injury: "50",
      oxygen: "50",
      motor: "50",
      status: "aktif",
    },
  ];

