import React from "react";
import "./AssociationBenchPress.css";
/* import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar"; */
import {
  Adrb2_freq_pie,
  Hif1a_freq_pie,
  Nos3_freq_pie,
} from "./TeamAlleleFreq.js";
import {
  Tooltip,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
} from "recharts";
//Benchpress Data
const benchpress_adrb2 = [
  { name: "AA", value: 57.5 },
  { name: "AG", value: 52.0 },
  { name: "GG", value: 60.5 },
];
const benchpress_hif1a = [
  { name: "CC", value: 49.44 },
  { name: "CT", value: 48.33 },
  { name: "TT", value: 65.0 },
];
const benchpress_nos3 = [
  { name: "CC", value: 50.0 },
  { name: "CT", value: 45.0 },
  { name: "TT", value: 55.83 },
];
const AssociationBenchpress = () => {
  return (
    <div>
      <div>
        <h1 fontFamily="Poppins">Bench Press</h1>

        <div className="grid-container">
          <div className="grid-item1">
          <Adrb2_freq_pie/>
          </div>
          <div className="grid-item2">
          <Hif1a_freq_pie/>
          </div>
          {/* <div className="grid-item3">
          <Nos3_freq_pie/>
          </div> */}
          <div className="grid-item3">
            <h3 className="genephysicalbarcharttitle">ADRB2 Related with Bench Press</h3>
            <BarChart
              width={500}
              height={300}
              data={benchpress_adrb2}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                barSize={20}
                name="Benchpress KG"
                dataKey="value"
                fill="#0e4194"
              />
            </BarChart>
          </div>
          <div className="grid-item4">
            <h3 className="genephysicalbarcharttitle">HIF1-A Related with Benchpress</h3>
            <BarChart
              width={500}
              height={300}
              data={benchpress_hif1a}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                label={{
                  value: "KG",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Bar
                barSize={20}
                name="Benchpress KG"
                dataKey="value"
                fill="#0e4194"
              />
            </BarChart>
          </div>
          {/* <div className="grid-item6">
            NOS3 Related with Benchpress
            <BarChart
              width={500}
              height={300}
              data={benchpress_nos3}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                label={{
                  value: "KG",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Bar
                barSize={20}
                name="Benchpress KG"
                dataKey="value"
                fill="#0e4194"
              />
            </BarChart>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AssociationBenchpress;
