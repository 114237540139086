import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import NewPlayer from './newPlayer/NewPlayer';
import './NewPlayerPage.css'

const NewPlayerPage = () => {
    return (
        <div className="newplayerpagewrapper">
            <Topbar />
            <div className="newplayerpagecontainer">
                <Sidebar />
                <div className='newplayerpagesubcontainer'>
                    <NewPlayer />
                </div>
            </div>
        </div>
    )
}

export default NewPlayerPage