import React from "react";
import "./PlayerProfile.css";
import random_avatar from "../../assets/image/random_avatar.jpg";
/* import SampleRadarPlotImg from "../../assets/image/sampleradarplot.png" */
import RadarPlot from "../../components/chart/RadarPlot";
/* import FakePhysicalChart from "../../components/chart/FakePhysicalChart"; */
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import $ from "jquery";

export default function PlayerProfile() {
  const genes = [
    {
      name: "actn3",
      genelab: "rs1815739",
      genotype: [
        {
          name: "RR",
          genotypedetails: [
            "RR genotype provides explosive Type II muscle fiber production.",
          ],
        },
        {
          name: "RX",
          genotypedetails: [
            "Has a good chance of adapting to some explosive power training and endurance training.",
          ],
        },
        {
          name: "XX",
          genotypedetails: [
            "XX genotype provides Type I muscle fiber production. There is a possibility of injury in muscle injuries and eccentric exercises.",
          ],
        },
      ],
    },

    {
      name: "col5a1",
      genelab: "rs12722",
      genotype: [
        {
          name: "CC",
          genotypedetails: [
            "Injuries such as non-impact injuries, Achilles tendinopathy, anterior cruciate ligament ruptures, and muscle cramps due to training are less likely to occur.",
          ],
        },
        {
          name: "TC",
          genotypedetails: [
            "The possibility of non-impact injuries, injuries such as Achilles tendinopathy, anterior cruciate ligament ruptures, and muscle cramps due to training moderately occurs. Muscles are less likely to show economy of motion.",
          ],
        },
        {
          name: "TT",
          genotypedetails: [
            "There may be a delayed recovery period in non-impact connective tissue injuries. In sports such as triathlon and long-distance running that require endurance, athletes may complete the race faster due to the economy of motion",
          ],
        },
      ],
    },

    {
      name: "col1a1",
      genelab: "rs1800012",
      genotype: [
        {
          name: "GG",
          genotypedetails: [
            "Tendinopathy, anterior cruciate ligament ruptures, shoulder dislocations and similar soft tissue injuries may be more likely to occur.",
          ],
        },
        {
          name: "TG",
          genotypedetails: [
            "Tendinopathy, anterior cruciate ligament ruptures, shoulder dislocations and similar soft tissue injuries may be moderately likely to occur.",
          ],
        },
        {
          name: "TT",
          genotypedetails: [
            "Tendinopathy and anterior cruciate ligament injuries are less common in soft tissue injuries.",
          ],
        },
      ],
    },
    {
      name: "il6",
      genelab: "rs1800795",
      genotype: [
        {
          name: "CC",
          genotypedetails: [
            "Has a good chance of adapting to endurance training. Muscle fatigue and muscle injuries may tend to heal later. Fat burning rate may be low",
          ],
        },
        {
          name: "CG",
          genotypedetails: [
            "Training-related muscle fatigue and muscle injuries may tend to heal later. Fat burning rate may be moderate.",
          ],
        },
        {
          name: "GG",
          genotypedetails: [
            "May respond better to explosive strength training. Muscle fatigue and muscle injuries due to training may tend to heal faster. Fat burning rate and energy produced in the muscles tend to be at a higher level.",
          ],
        },
      ],
    },
    {
      name: "mct1",
      genelab: "rs1049434",
      genotype: [
        {
          name: "AA",
          genotypedetails: [
            "It can show better adaptation in long-term high-intensity training. While muscle fatigue and cramps are less common; more prone to muscle injuries.",
          ],
        },
        {
          name: "AT",
          genotypedetails: [
            "Has a good chance of adapting to high-intensity training and strength-based training. Muscle cramps and fatigue may occur more frequently. It has a higher chance of having lean body mass.",
          ],
        },
        {
          name: "TT",
          genotypedetails: [
            "Muscle fatigue and cramps are more common. It has a higher chance of adapting to the explosive power-based type of training. It has a higher chance of having lean body mass.",
          ],
        },
      ],
    },
    {
      name: "adrb2",
      genelab: "rs1042713",
      genotype: [
        {
          name: "GG",
          genotypedetails: [
            "GG genotype may lead to a higher body mass index and an increase in systolic blood pressure and triglyceride fat level in the blood.",
          ],
        },
        {
          name: "AG",
          genotypedetails: [
            "Body fat tends to be high. body mass index, blood pressure, and blood triglyceride fat can be moderately high. This genotype has a higher chance to adapt to strength exercises.",
          ],
        },
        {
          name: "AA",
          genotypedetails: [
            "Body fat storage rate is lower. In endurance sports, mobility has improved due to low body mass index. Cardiac output tends to be lower.",
          ],
        },
      ],
    },
    {
      name: "hif1a",
      genelab: "rs11549465",
      genotype: [
        {
          name: "TT",
          genotypedetails: [
            "TT genotype shows higher adaptability in power-based sports. In regular training, the increase in VO2max may be lower. Glucose metabolism tends to be higher. ",
          ],
        },
        {
          name: "CT",
          genotypedetails: [
            " Body muscles tend to produce Type II muscle fibers. Depending on the type of training, the VO2max level can be developed moderately and it can be adapted to power training.",
          ],
        },
        {
          name: "CC",
          genotypedetails: [
            "With regular training, VO2max level is greatly improved. This genotype is more adaptable to endurance training."
          ],
        },
      ],
    },
    {
      name: "nos3",
      genelab: "rs2070744",
      genotype: [
        {
          name: "TT",
          genotypedetails: [
            "Better adaptation may be observed in explosive power. Anaerobic capacity and blood pressure tends to be higher.",
          ],
        },
        {
          name: "CT",
          genotypedetails: [
            "TC genotype responds better to strength training. It can show moderate adaptation to aerobic training, an increase in blood pressure occurs.",
          ],
        },
        {
          name: "CC",
          genotypedetails: [
            "There may be lower blood pressure and an increase in aerobic capacity. A higher rate of adaptation to long-term endurance sports can be observed."
          ],
        },
      ],
    },
    {
      name: "ppargc1a",
      genelab: "rs8192678",
      genotype: [
        {
          name: "AA",
          genotypedetails: [
            "AA genotype may lead to Type II fiber production, an increase in the level of fatty acids in the blood and a decrease in fat burning at the same time. Explosive power-based trainings may respond better.",
          ],
        },
        {
          name: "AG",
          genotypedetails: [
            "With endurance exercises, an increase in the rate of slow twitch Type I muscle group may occur. An increase in the level of fatty acids in the blood and a decrease in the balance of fat metabolism can be observed",
          ],
        },
        {
          name: "GG",
          genotypedetails: [
            "There may be a better response to endurance sports. Type I muscle fibers are developed rapidly with endurance exercises. Oxygen capacity and fat burning respond better to the type of endurance training. An increase in aerobic capacity may be observed."
          ],
        },
      ],
    },
    {
      name: "ppara",
      genelab: "rs4253778",
      genotype: [
        {
          name: "CC",
          genotypedetails: [
            "In explosive power-based training, high performance and more muscle mass can be created. Fat metabolism tends to be lower.",
          ],
        },
        {
          name: "CG",
          genotypedetails: [
            "Explosive power training can show faster improvement. More muscle mass formation can be achieved. A decrease in the fat breakdown balance can be observed.",
          ],
        },
        {
          name: "GG",
          genotypedetails: [
            "You can respond better to endurance training, have more slow-twitch Type I muscle types. The maximum oxygen consumption of the muscles may be higher and the balance of fat breakdown may be improved."
          ],
        },
      ],
    },
    {
      name: "comt",
      genelab: "rs4253778",
      genotype: [
        {
          name: "GG",
          genotypedetails: [
            "With exercise, the development of cognitive functions is higher, short-term memory development is less common. Resistance to stress and ability to make decisions under stress have developed. Competition and innovation seeking are lower.",
          ],
        },
        {
          name: "AG",
          genotypedetails: [
            "Tends to have developable short-term memory and cognitive functions. Competitive and open to innovations, risk-taking tendency in sports can be observed at a moderate level.",
          ],
        },
        {
          name: "AA",
          genotypedetails: [
            "Shows more interest in risk taking and openness to innovation. They have enhanced competitive feelings and have a lower tendency to avoid harm. Short-term memory and attention are more developed."
          ],
        },
      ],
    },
    {
      name: "bdnf",
      genelab: "rs6265",
      genotype: [
        {
          name: "GG",
          genotypedetails: [
            "An increase in cognitive functions, learning skills, and the establishment of new neural connections are observed. It has a protective effect on emotional functions, anxiety and various mood changes.",
          ],
        },
        {
          name: "AG",
          genotypedetails: [
            "Moderate increase in learning ability, establishment of new neural connections can be observed.",
          ],
        },
        {
          name: "AA",
          genotypedetails: [
            "The increase in learning ability, establishment of new neural connections is observed at a lower level."
          ],
        },
      ],
    },
  ];

  const score = [
    {
      type: "power",
      title: "Strength and Endurance Training Recommendations",
      explanation: [
        {
          value: "0-30",
          valueexplanation: [
            "You respond better to endurance training, you can include low-impact aerobic training with a higher load duration in your programs. For example, continuous low-paced running can improve your overall endurance performance, while intermittent jogging helps your aerobic endurance. By increasing the duration of the exercise sets (high repetitions), you can benefit more from the aerobic feature. During your training session, you can be aware of your heart rate, and oxygen level in the environment and you might avoid the uneven training ground. ",
          ],
        },
        {
          value: "30-60",
          valueexplanation: [
            "To increase your response to endurance training besides your explosiveness, you can choose interval-paced workouts. With the ability of explosive training types, you can increase your endurance ability by including low-paced long interval exercises. For example, in workouts like jogging, you can include slow-paced continuous jogs that lower the heart rate throughout high-paced intervals. In cardio workouts, you can increase the set reps up to 20 or more. To maintain the health of your joints and muscles, it is important to include rest periods between sets of exercises. During your training session, you can be aware of your heart rate, and oxygen level in the environment and you might avoid the uneven training ground. ",
          ],
        },
        {
          value: "60-80",
          valueexplanation: [
            "Because you respond better to explosive exercises, you can include high-impact weight training as well as exercises that support explosiveness, such as jumping, push-ups, weight lifting, and kettlebell swings. Applying high threshold performance training will give positive results in terms of hypertrophy and your power-based performance. You can add mid-level load strength exercises with higher weights and minimum repetitions and average set number.When choosing this type of training, you should pay attention to using weights suitable for you and rest intervals due to training intensity.",
          ],
        },
        {
          value: "80-100",
          valueexplanation: [
            "Because you respond better to explosive exercises, you can include high-impact weight training as well as exercises that support explosiveness, such as jumping, push-ups, weight lifting, and kettlebell swings. Applying high threshold performance training will give positive results in terms of hypertrophy and your power-based performance. You can add mid-level load strength exercises with higher weights and minimum repetitions and average set number. When choosing this type of training, you should pay attention to using weights suitable for you and rest intervals due to training intensity.",
          ],
        },
      ],
    },

    {
      type: "injury",
      title: "Injury Risk Training Recommendations",
      explanation: [
        {
          value: "0-30",
          valueexplanation: [
            "Your risk of non-impact injury is low. By including warm-up and cool-down exercises in your training you can eliminate the risks of tendinopathy, and cruciate ligament ruptures. During your training session, you can be aware of the selection of shoes and other equipment suitable for the training ground that will help you reduce all possible risks.",
          ],
        },
        {
          value: "30-60",
          valueexplanation: [
            "Your risk of non-impact injury is moderate. You can reduce all possible risks by including warm-up and cool-down exercises in your workouts.  You can add exercises such as unidirectional balancing on one leg, and lifting weights on one leg (split squat, single leg deadlift) to your regular exercises to strengthen the hip and lower leg joints. During your training session, you can be aware of the selection of shoes and other equipment suitable for the training ground that will help you reduce all possible risks.",
          ],
        },
        {
          value: "60-80",
          valueexplanation: [
            "Your risk of non-impact injury is moderately high. You can include warm-up and cool-down exercises before and after training. In order to prevent cruciate ligament injuries and tendinopathy and also strengthen the hip and lower leg joints, you can add exercises such as unidirectional balancing on one leg, and lifting weights on one leg (split squat, single leg deadlift) into your regular exercises. To eliminate shoulder and arm injuries, you can add overhead exercises such as push-ups and planks to strengthen your arms and shoulders to your training program over time. ",
          ],
        },
        {
          value: "80-100",
          valueexplanation: [
            "Your risk of non-impact injury is high. You can include warm-up and cool-down exercises before and after training. In order to prevent cruciate ligament injuries and tendinopathy and also strengthen the hip and lower leg joints, you can add exercises such as unidirectional balancing on one leg, and lifting weights on one leg (split squat, single leg deadlift) into your regular exercises. To eliminate shoulder and arm injuries, you can add overhead exercises such as push-ups and planks to strengthen your arms and shoulders to your training program over time. ",
          ],
        },
      ],
    },

    {
      type: "oxygen",
      title: "Oxygen Capacity Training Recommendations",
      explanation: [
        {
          value: "0-30",
          valueexplanation: [
            "Low VO2 max level has been associated with tissue oxygen utilization and hypoxia state. When exercise is performed in environments with insufficient oxygen levels, the tissues may not be fully productive from this situation. It is important to be careful in aerobic exercises (long-term, endurance-requiring exercise types such as swimming, running, and cycling). You should be aware of the balance of aerobic and anaerobic exercises  (explosive, jumping, weight lifting, sprinting, and similar explosive training methods).  You can select the appropriate weights according to your muscle structure.",
          ],
        },
        {
          value: "30-60",
          valueexplanation: [
            "Low VO2max level has been associated with tissue oxygen utilization and hypoxia state. When exercise is performed in environments with insufficient oxygen levels, the tissues may not be fully productive from this situation. It is important to be careful in aerobic exercises (such as swimming, running, cycling, and long-term endurance-requiring exercise). In your training, you should be aware of the balance of aerobic and anaerobic exercises  (explosive, jumping, weight lifting, sprinting, and similar explosive training methods).  You can select the appropriate weights according to your muscle structure.",
          ],
        },
        {
          value: "60-80",
          valueexplanation: [
            "Due to your medium to high oxygen capacity, you can get more advantages in aerobic training. You can achieve a higher degree of adaptation by focusing on long-term, endurance-oriented training such as swimming, running, and cycling which increase the level of aerobic endurance. You have a high chance of getting efficiency from cardio-like workouts.",
          ],
        },
        {
          value: "80-100",
          valueexplanation: [
            "Due to your high oxygen capacity, you can get more advantages in aerobic training. You can achieve a higher degree of adaptation by focusing on long-term, endurance-oriented trainings such as running, and cycling which increase the level of aerobic endurance. You have a high chance of getting efficiency from cardio-like workouts.",
          ],
        },
      ],
    },

    {
      type: "motor",
      title: "Motor Skills Training Recommendations",
      explanation: [
        {
          value: "0-50",
          valueexplanation: [
            "Motor learning skills refer to features such as establishing new neural connections, risk-taking, and competitiveness. In terms of these features, you have a learning skill that can be improved. In your training or in the sports field you choose, gradually increasing the complexity of the movements can provide a more comfortable learning process. You can consider this adaptation feature when choosing an individual or team sports.",
          ],
        },
        {
          value: "50-100",
          valueexplanation: [
            "Motor learning skills refer to features such as establishing new neural connections, risk-taking, and competitiveness. Due to your high motor learning ability, your adaptation process to complex and difficult training would be higher. You can get an advantage in training in terms of taking action, competitiveness, learning, and cognitive functions. Short-term memory can develop faster, so you have a good chance of adapting to repetitive training.",
          ],
        },
      ],
    },
  ];

  const [player, setPlayer] = useState([]);
  const [genotype, setGenotype] = useState([]);
  const [genotypedetails, setGenotypedetails] = useState([]);
  const [selectedGene, setSelectedGene] = useState("");
  const [selectedParameter, setSelectedParameter] = useState("");
  const [parameterdetails, setParameterdetails] = useState([]);

  $(document).ready(function () {
    $("#myselection").on("change", function () {
      var value = $(this).val();
      $("div.myDiv").hide();
      $("#show" + value).show();
    });
  });

  const id = useParams();
  const id_number = id["playerId"];
  const [GeneralScore, setGeneralScore] = useState(null);
  const [new_player_data, setNewPlayerData] = useState(null);
  //New Player Data, General Score Data

  React.useEffect(() => {
    axios.get("/api/new_player_view").then((response) => {
      setNewPlayerData(response.data);
    });
    axios.get("/api/generalscore").then((response) => {
      setGeneralScore(response.data);
    });
    axios.post("/api/genotypes", { id: id_number }).then((response) => {
      setPlayer(response.data);
    });
  }, []);
  if (!new_player_data) return null;
  if (!GeneralScore) return null;
  ////PDF START
  const getPDF = async (id) => {
    await axios
      .get(`/api/get/${GeneralScore[id - 1]["swapcode"]}`, {
        responseType: "blob",
      })
      .then((res) => {
        if (res.data) {
          const file = new Blob([res.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      });
  };
  ////PDF END

  function changer(event) {
    const genotypeVal =
      player[genes.find((genes) => genes.name === event.target.value).genelab];
    setGenotype(genotypeVal);
    const stats = genes.find(
      (genes) => genes.name === event.target.value
    ).genotype;
    setGenotypedetails(
      stats.find((stat) => stat.name === genotypeVal).genotypedetails
    );
  }

  function changeGene(event) {
    setSelectedGene(event.target.value);

    switch (event.target.value) {
      case "actn3":
        changer(event);
        break;
      case "col5a1":
        changer(event);
        break;
      case "col1a1":
        changer(event);
        break;
      case "il6":
        changer(event);
        break;
      case "mct1":
        changer(event);
        break;
      case "adrb2":
        changer(event);
        break;
      case "hif1a":
        changer(event);
        break;
      case "nos3":
        changer(event);
        break;
      case "ppargc1a":
        changer(event);
        break;
      case "ppara":
        changer(event);
        break;
      case "comt":
        changer(event);
        break;
      case "bdnf":
        changer(event);
        break;
    }
  }


  function changeParameter(event) {
    setSelectedParameter(event.target.value);
    const selParam = score.find(
      (score) => score.title === event.target.value
    ).type;
    if (selParam === "motor") {
      const parameterVal = GeneralScore[id_number - 1][selParam];
      console.log(selParam);
      const stats = score.find((score) => score.type === selParam).explanation;
      console.log(stats);
      if (0 < parameterVal && parameterVal <= 50) {
        setParameterdetails(
          stats.find((stat) => stat.value === "0-50").valueexplanation
        );
      } else if (50 < parameterVal && parameterVal <= 100) {
        setParameterdetails(
          stats.find((stat) => stat.value === "50-100").valueexplanation
        );
      }
    } else {
      const parameterVal = GeneralScore[id_number - 1][selParam];
      const stats = score.find((score) => score.type === selParam).explanation;
      if (0 < parameterVal && parameterVal <= 30) {
        setParameterdetails(
          stats.find((stat) => stat.value === "0-30").valueexplanation
        );
      } else if (30 < parameterVal && parameterVal <= 60) {
        setParameterdetails(
          stats.find((stat) => stat.value === "30-60").valueexplanation
        );
      } else if (60 < parameterVal && parameterVal <= 80) {
        setParameterdetails(
          stats.find((stat) => stat.value === "60-80").valueexplanation
        );
      } else if (80 < parameterVal && parameterVal <= 100) {
        setParameterdetails(
          stats.find((stat) => stat.value === "80-100").valueexplanation
        );
      }
    }
  }

  return (
    <div className="product">
      <div className="productTop">
        <div className="productTopLeft">
          <h3>Athlete Radar Chart</h3>
          {/* <img src={SampleRadarPlotImg} alt="Player Radar Plot" className="radarImg" /> */}
          <RadarPlot id_number={id_number}></RadarPlot>
          {/*           <FakePhysicalChart id={id_number}></FakePhysicalChart> */}
        </div>
        <div className="productTopRight">
          <div className="productInfoTop">
            <img src={random_avatar} alt="" className="productInfoImg" />
            <span className="productName">
              {new_player_data[id_number - 1]["ad_soyad"]}
            </span>
          </div>
          <div className="productInfoBottom">
            <h5>Personal Information</h5>
            <div className="productInfoItem">
              <span className="productInfoKey">Position:</span>
              <span className="productInfoValue">
                {" "}
                {new_player_data[id_number - 1]["pozisyon"]}
              </span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Date of birth:</span>
              <span className="productInfoValue">
                {" "}
                {new_player_data[id_number - 1]["dogum_tarihi"]}
              </span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Status:</span>
              <span className="productInfoValue">
                {" "}
                {new_player_data[id_number - 1]["durum"]}
              </span>
            </div>
            <br></br>
            <h5>Genetic Score Information:</h5>
            <div className="productInfoItem">
              <span className="productInfoKey">Power Score:</span>
              <span className="productInfoValue">
                {GeneralScore[id_number - 1]["power"]}
              </span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Endurance Score:</span>
              <span className="productInfoValue">
                {GeneralScore[id_number - 1]["endurance"]}
              </span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Injury Risk:</span>
              <span className="productInfoValue">
                {GeneralScore[id_number - 1]["injury"]}
              </span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Oxygen Capacity:</span>
              <span className="productInfoValue">
                {GeneralScore[id_number - 1]["oxygen"]}
              </span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Motor Skills:</span>
              <span className="productInfoValue">
                {GeneralScore[id_number - 1]["motor"]}
              </span>
            </div>
            <br></br>
            <div>
              <button
                className="playerInfoReportButton"
                onClick={() => getPDF(id_number)}
              >
                PLAYER GENETIC REPORT
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="productTop">
        <div className="productTopLeft">
          <div className="geneparamsdetailwidgetLg">
            <h2>Genetic Parameter Information</h2>
            <p>
                You can learn our recommendations specific to your 
                genetic scores by selecting genetic parameters at below.
                For more information, please display genetic report of athlete.
            </p>
            <div>
              <div>
                <select
                  id="myselection"
                  placeholder="Select the parameter"
                  value={selectedParameter}
                  onChange={changeParameter}
                >
                  <option>Select Parameter</option>
                  {score.map((e, key) => {
                    return (
                      <option key={key}>
                        {" "}
                        <strong>{e.title}</strong>
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <p>{parameterdetails}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="productTopLeft">
          <div className="geneparamsdetailwidgetLg">
            <h2>Summary of Genotype Information</h2>
            <p>You can learn summary of genotypes by selecting genes at below.
                For more information, please display genetic report of athlete.</p>
            <div>
              <select
                placeholder="Select Gene"
                value={selectedGene}
                onChange={changeGene}
                style={{ textTransform: "uppercase" }}
              >
                <option>Select Gene</option>
                {genes.map((e, key) => {
                  return <option key={key}>{e.name}</option>;
                })}
              </select>
            </div>
            <div>
              <h5><strong>Genotype</strong></h5>
              <p>{genotype}</p>
            </div>

            <div>
              <h5><strong>Genotype Details</strong></h5>
              <p>{genotypedetails}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
