import React from "react";
import "./topbar.css";
import { NotificationsNone, Language, Settings } from "@material-ui/icons";
/* import FenerbahceLogo from '../../assets/logo/fenerbahce_logo.png'; */
/* import UniqgeneLogo from '../../assets/logo/uniqgene_logo.jpg'; */
import CricketLogo from "../../assets/logo/cricket_logo.jpg";
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

export default function Topbar() {

  const navigate = useNavigate();
  const Logout = async () => {
    try {
        await axios.delete('/api/logout');
        navigate("/");
    } catch (error) {
        console.log(error);
    }
}

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <Link to="/home" className="topLeft">
          <img src={CricketLogo} alt="UniqgeneLogo" className="teamlogo" />
          <span className="logo">
            UAE National Cricket Team Performance Dashboard
          </span>
        </Link>
        <div className="topRight">
          <div className="topbarIconContainer">
            <Link to="/answers">
              <NotificationsNone />
            </Link>
            <span className="topIconBadge">2</span>
          </div>

          {/*           <div className="topbarIconContainer">
            <div className="dropdown">
              <button className="dropbtn">
                <Language />
              </button>
              <div className="dropdown-content">
                <a href="/">English</a>
                <a href="/home">Türkçe</a>
              </div>
            </div>
          </div> */}

          <div className="topbarIconContainer">
            <Settings />
          </div>
          <div className="topbarIconContainer">
            <button onClick={Logout} className="dropbtn">
              <LogoutIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
