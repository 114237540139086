import React from 'react'
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import './SendMessagePage.css'
import { useState } from "react";
import Axios from "axios";
import { display } from "@mui/system";

const SendMessagePage = () => {
  const [konu, setKonu] = useState("");
  const [mesaj, setMesaj] = useState("");
  const sendMessage = () => {
    Axios.post("/api/sendmessage", {
      konu: konu,
      mesaj: mesaj,
    }).then((response) => {
      console.log(response);
    });
  };
  return (
    <div className="sendmessageswrapper">
      <Topbar />
      <div className="sendmessagescontainer">
        <Sidebar />
        <div className="sendmessagessubcontainer">
          <form>
            <label for="fname">Subject</label>
            <input
              type="text"
              id="fname"
              name="subject"
              placeholder="Enter the subject title."
              onChange={(event) => {
                setKonu(event.target.value);
              }}
            />

            <label for="subject">Message</label>
            <textarea
              id="subject"
              name="subject"
              placeholder="You can write your requests in this section.&#10;We will contact you within 1 day."
              onChange={(event) => {
                setMesaj(event.target.value);
              }}
            ></textarea>

            <button type="submit" value="Submit" onClick={sendMessage}>
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SendMessagePage