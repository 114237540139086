import React from "react";
import { Cell, PieChart, Pie, Tooltip } from "recharts";

const COLORS = ["#4682b4", "#b0c4de", "#FFBB28", "#FF8042"];
const COLORS_demo = ["#a4162b", "#49c2f9"]; //sol,orta,sag
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderCustomizedLabel_demo = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
//demo Frekansları
const actn3_freq = [
  { name: "Percentage of People with C Allele in Uniqgene", value: 52 },
  { name: "Percentage of People with T Allele in Uniqgene", value: 48 },
];
const col5a1_freq = [
  { name: "Percentage of People with C Allele in Uniqgene", value: 44 },
  { name: "Percentage of People with T Allele in Uniqgene", value: 56 },
];
const nos3_freq = [
  { name: "Percentage of People with C Allele in Uniqgene", value: 34 },
  { name: "Percentage of People with T Allele in Uniqgene", value: 66 },
];
const hif1a_freq = [
  { name: "Percentage of People with C Allele in Uniqgene", value: 84 },
  { name: "Percentage of People with T Allele in Uniqgene", value: 16 },
];
const adrb2_freq = [
  { name: "Percentage of People with G Allele in Uniqgene", value: 60 },
  { name: "Percentage of People with A Allele in Uniqgene", value: 40 },
];

//Avrupa Frekansları
const avrupa_actn3_freq = [
  { name: "Percentage of People with C Allele in Europe", value: 56 },
  { name: "Percentage of People with T Allele in Europe", value: 44 },
];
const avrupa_col5a1_freq = [
  { name: "Percentage of People with C Allele in Europe", value: 41 },
  { name: "Percentage of People with T Allele in Europe", value: 58 },
];
const avrupa_adrb2_freq = [
  { name: "Percentage of People with G Allele in Europe", value: 61 },
  { name: "Percentage of People with A Allele in Europe", value: 38 },
];
const avrupa_hif1a_freq = [
  { name: "Percentage of People with C Allele in Europe", value: 90 },
  { name: "Percentage of People with T Allele in Europe", value: 10 },
];
const avrupa_nos3_freq = [
  { name: "Percentage of People with C Allele in Europe", value: 44 },
  { name: "Percentage of People with T Allele in Europe", value: 56 },
];

function Actn3_freq_pie() {
  return (
    <div>
      <h3>ACTN3</h3>
      <h4>
        It is mainly effective in determining which muscle fiber type (Type I
        and Type II) you have.
      </h4>
      ACTN3 Frequency Plot
      <PieChart width={400} height={400}>
        <Tooltip />
        <Pie
          data={actn3_freq}
          cx="50%"
          cy="50%"
          labelLine={false}
          isAnimationActive={true}
          label={renderCustomizedLabel_demo}
          outerRadius={70}
          fill="#8884d8"
          dataKey="value"
        >
          {actn3_freq.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS_demo[index % COLORS_demo.length]}
            />
          ))}
        </Pie>

        <Pie
          data={avrupa_actn3_freq}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={80}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {avrupa_actn3_freq.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <h4>
        C: Fast twitch Type II muscle fibers have a better chance of adapting to
        anaerobic training and faster muscle fatigue may occur.
      </h4>
      <h4>
        T: There is a possibility of experiencing muscle injuries and ankle
        injuries.
      </h4>
    </div>
  );
}
function Nos3_freq_pie() {
  return (
    <div>
      <h3>NOS3</h3>
      <h4>
        The expansion of the vasculature is involved in the delivery of oxygen
        to the tissues and affects the level of adaptation to aerobic -
        anaerobic training.
      </h4>
      NOS3 Frequency Plot
      <PieChart width={400} height={400}>
        <Tooltip />
        <Pie
          data={nos3_freq}
          cx="50%"
          cy="50%"
          labelLine={false}
          isAnimationActive={true}
          label={renderCustomizedLabel_demo}
          outerRadius={70}
          fill="#8884d8"
          dataKey="value"
        >
          {nos3_freq.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS_demo[index % COLORS_demo.length]}
            />
          ))}
        </Pie>

        <Pie
          data={avrupa_nos3_freq}
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={80}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {avrupa_nos3_freq.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <h4>
        T: In the muscles, especially rapid growth and better adaptation are
        observed in training based on explosive power. Aerobic capacity is low.
        There is an increase in blood pressure.
      </h4>
      <h4>
        C: Lower blood pressure and an increase in aerobic capacity may occur. A
        higher rate of adaptation to long-term endurance sports and moderate
        adaptation to aerobic training can be shown.
      </h4>
    </div>
  );
}

function Col5a1_freq_pie() {
  return (
    <div>
      <h3>COL5A1</h3>
      <h4>
        It is one of the genes involved in collagen production and is related to
        disability and recovery times.
      </h4>
      COL5A1 Frequency Plot
      <PieChart width={400} height={400}>
        <Tooltip />
        <Pie
          data={col5a1_freq}
          cx="50%"
          cy="50%"
          labelLine={false}
          isAnimationActive={true}
          label={renderCustomizedLabel_demo}
          outerRadius={70}
          fill="#8884d8"
          dataKey="value"
        >
          {col5a1_freq.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS_demo[index % COLORS_demo.length]}
            />
          ))}
        </Pie>

        <Pie
          data={avrupa_col5a1_freq}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={80}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {avrupa_col5a1_freq.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <h4>
        C: Tendons have more flexibility, less likely to be injuries such as
        non-impact injuries, Achilles tendinopathy, anterior cruciate ligament
        ruptures. Muscles are less likely to show economy of motion.
      </h4>
      <h4>
        T: Care should be taken in movements that require flexibility in sports,
        and attention should be paid to muscle and connective tissues.
      </h4>
    </div>
  );
}

function Hif1a_freq_pie() {
  return (
    <div>
      <h3>HIF1a</h3>
      <h4>
        It is found in oxidative muscle fibers and affects the level of
        adaptation to aerobic - anaerobic training.
      </h4>
      HIF1A Frequency Plot
      <PieChart width={400} height={400}>
        <Tooltip />
        <Pie
          data={hif1a_freq}
          cx="50%"
          cy="50%"
          labelLine={false}
          isAnimationActive={true}
          label={renderCustomizedLabel_demo}
          outerRadius={70}
          fill="#8884d8"
          dataKey="value"
        >
          {hif1a_freq.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS_demo[index % COLORS_demo.length]}
            />
          ))}
        </Pie>

        <Pie
          data={avrupa_hif1a_freq}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={80}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {avrupa_hif1a_freq.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <h4>
        T: Higher adaptability can be demonstrated in power-based sports.
        Although the increase in VO2max level is lower in regular training, an
        increase in glucose metabolism can be observed.
      </h4>
      <h4>C: More adaptable to endurance training.</h4>
    </div>
  );
}

function Adrb2_freq_pie() {
  return (
    <div>
      <h3>ADRB2</h3>
      <h4>
        It affects many important physical conditions such as energy metabolism,
        response to stress, blood pressure, vascular formation and heart
        function.
      </h4>
      ADRB2 Frequency Plot
      <PieChart width={400} height={400}>
        <Tooltip />
        <Pie
          data={adrb2_freq}
          cx="50%"
          cy="50%"
          labelLine={false}
          isAnimationActive={true}
          label={renderCustomizedLabel_demo}
          outerRadius={70}
          fill="#8884d8"
          dataKey="value"
        >
          {adrb2_freq.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS_demo[index % COLORS_demo.length]}
            />
          ))}
        </Pie>
        <Pie
          data={avrupa_adrb2_freq}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={80}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {avrupa_adrb2_freq.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <h4>
        G: Tends to be high in body fat. An increase in body mass index, blood
        pressure, and blood triglyceride fat levels may occur.
      </h4>
      <h4>
        A: Body fat storage rate is lower. Strength tends to be high. Cardiac
        output is lower.
      </h4>
    </div>
  );
}
export {
  Actn3_freq_pie,
  Adrb2_freq_pie,
  Col5a1_freq_pie,
  Hif1a_freq_pie,
  Nos3_freq_pie,
};
/* const TeamAlleleFreq = () => {
  return (
    <div>
      <div className="freq_grid-container">
        <div className="freq_grid-item1"></div>
        <div className="freq_grid-item2"></div>
        <div className="freq_grid-item3"></div>
        <div className="freq_grid-item4"></div>
        <div className="freq_grid-item5"></div>
      </div>
    </div>
  );
};

export default TeamAlleleFreq;
 */
