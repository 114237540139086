import React from 'react'
import Sidebar from '.././components/sidebar/Sidebar';
import Topbar from '.././components/topbar/Topbar';
import ScoreCard from '.././components/scorecard/ScoreCard';
import ScoreList from './scoreList/ScoreList';
import "./GeneticScoresPage.css"

const GeneticScoresPage = () => {
  return (
      <div className="geneticscoreswrapper">
        <Topbar />
        <div className="geneticscorescontainer">
          <Sidebar />
          <div className="geneticscoressubcontainer">
            <ScoreCard />
            <ScoreList />
          </div>
        </div>
      </div>
  )
}

export default GeneticScoresPage
